
//== Variables

@import 'site/variables';
@import 'site/grid';
@import 'site/mixin';

//== Site global

@import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/base';

//== Core components

@import 'src/components/text/_text.scss';

@import 'src/components/title/_title.scss';

@import 'src/components/image/_image.scss';

@import 'src/components/button/_button.scss';

@import 'src/components/separator/_separator.scss';

@import 'src/components/progressbar/_progressbar.scss';

@import 'src/components/teaser/_teaser.scss';

@import 'src/components/download/_download.scss';

//== Lists and Navigation components

@import 'src/components/list/_list.scss';

@import 'src/components/navigation/_navigation.scss';

@import 'src/components/languagenavigation/_languagenavigation.scss';

@import 'src/components/breadcrumb/_breadcrumb.scss';

@import 'src/components/search/_search.scss';

@import 'src/components/tableofcontents/_tableofcontents.scss';

//== Container components

@import 'src/components/container/_container.scss';

@import 'src/components/carousel/_carousel.scss';

@import 'src/components/tabs/_tabs.scss';

@import 'src/components/accordion/_accordion.scss';

//== Fragments (CF + XF) components

@import 'src/components/contentfragment/_contentfragment.scss';
@import 'src/components/contentfragmentlist/_contentfragmentlist.scss';

@import 'src/components/experiencefragment/_experiencefragment.scss';

//== Form components

@import 'src/components/form/_form.scss';
@import 'src/components/form-button/_form-button.scss';
@import 'src/components/form-text/_form-text.scss';
@import 'src/components/form-options/_form-options.scss';

//== Embed components

@import 'src/components/embed/_embed.scss';

@import 'src/components/pdfviewer/_pdfviewer.scss';

@import 'src/components/socialmediasharing/_social_media_sharing.scss';

//== Custom components

@import 'src/components/news-index/_news-index.scss';

@import 'src/components/article-title/_article-title.scss';

@import 'src/components/notifications/_notifications.scss';

// == CEH components
@import 'src/site/_ceh_mixin.scss';

@import 'src/components/ceh-heading/_ceh-heading.scss';

@import 'src/components/ceh-text/_ceh_text.scss';

@import 'src/components/ceh-annotation/_annotation.scss';

@import 'src/components/ceh-anchorlinklist/_ceh-anchorlinklist.scss';

@import 'src/components/ceh-button/_ceh-button.scss';

@import 'src/components/ceh-list/_ceh-list.scss';

@import 'src/components/ceh-linklist/_ceh-linklist.scss';

@import 'src/components/ceh-card/_ceh-card.scss';

@import 'src/components/ceh-indextitle/_ceh-indextitle.scss';

@import 'src/components/ceh-indexmenu/_ceh-indexmenu.scss';

@import 'src/components/ceh-alert/_ceh-alert.scss';

@import 'src/components/ceh-tabs/_ceh-tabs.scss';

@import 'src/components/ceh-cf-textimg/_ceh-cf-textimg.scss';
