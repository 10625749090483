.cmp-button--icon-another-window {
  .cmp-button {
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      margin-left: 16px;
      margin-right: 16px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' fill='none' viewBox='0 0 14 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.108 4.067a.583.583 0 0 1 .825 0L9.6 8.733a.583.583 0 0 1 0 .825l-4.667 4.667a.583.583 0 1 1-.825-.825l4.255-4.254-4.255-4.254a.583.583 0 0 1 0-.825Z' clip-rule='evenodd'/%3e%3c/svg%3e");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      margin-left: 16px;
      margin-right: 16px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.531 7.583a.51.51 0 0 1 .51-.51h1.45v1.02h-.939v3.063h4.375V9.914h1.02v1.753a.51.51 0 0 1-.51.51H2.043a.51.51 0 0 1-.51-.51V7.583Z'/%3e%3cpath d='M4.74 2.042a.51.51 0 0 1 .51-.51h7a.51.51 0 0 1 .51.51v6.125a.51.51 0 0 1-.51.51h-7a.51.51 0 0 1-.51-.51V2.042Zm1.02.51v5.104h5.98V2.552H5.76Z'/%3e%3c/g%3e%3c/svg%3e");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
