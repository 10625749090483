.cmp-container--layout-fixedimage {
  > .cmp-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      row-gap: 16px;
    }

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
      column-gap: 48px;
      row-gap: 32px;
    }

    > * {
      @include media-breakpoint-up(md) {
        width: 360px;
      }
    }
  }
}
