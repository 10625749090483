.ceh-annotation__list--dot-cjk {
  & > .ceh-annotation__item {
    &::before {
      content: "";
      width: 1em;
      height: 1em;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' fill='none' viewBox='0 0 6 6'%3e%3crect width='6' height='6' fill='%237F7F7F' rx='3'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: top 0.6em left 0.2em;
    }
  }
}

ol.ceh-annotation__list--dot-cjk {
  & > .ceh-annotation__item {
    &::before {
      content: counter(annotationcount);
      padding-left: 1em;
    }
  }
}