.ceh-annotation__list--asterisk {
  & > .ceh-annotation__item {
    &::before {
      content: "*";
    }
  }
}

ol.ceh-annotation__list--asterisk {
  & > .ceh-annotation__item {
    &::before {
      content: "*" counter(annotationcount)"";
    }
  }
}