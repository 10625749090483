.cmp-teaser--style-banner {
  .cmp-teaser {
    &__link {
      display: flex;
      border-style: solid;
      border-color: $color-cosmo-blue;
      border-width: 2px;
      border-radius: 15px;
      background-color: $color-cosmo-blue;
      color: $color-text-inverted;
      text-decoration: none;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
        align-items: center;
      }

      &:hover,
      &:active,
      &:focus {
        opacity: .7;
      }

      &[target='_blank'] {
        .cmp-teaser__title {
          display: flex;
          align-items: center;

          &::after {
            content: '';
            width: 15px;
            height: 13px;
            margin-left: 8px;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215%22%20height%3D%2213%22%20viewBox%3D%220%200%2015%2013%22%3E%3Cg%20transform%3D%22translate(0%205)%22%20fill%3D%22%232c31a6%22%20stroke%3D%22%23fff%22%20stroke-width%3D%221%22%3E%3Crect%20width%3D%2211%22%20height%3D%228%22%20stroke%3D%22none%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%2F%3E%3C%2Fg%3E%3Cg%20transform%3D%22translate(4)%22%20fill%3D%22%232c31a6%22%20stroke%3D%22%23fff%22%20stroke-width%3D%221%22%3E%3Crect%20width%3D%2211%22%20height%3D%228%22%20stroke%3D%22none%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }


    &__image {
      @include media-breakpoint-up(md) {
        width: 50%;
      }

      .cmp-image__image {
        @include media-breakpoint-down(md) {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }

        @include media-breakpoint-up(md) {
          height: 182px;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          object-fit: cover;
        }
      }
    }


    &__content {
      @include media-breakpoint-down(md) {
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 24px;
        padding-bottom: 32px;
      }

      @include media-breakpoint-up(md) {
        width: 50%;
        padding-left: 37px;
      }
    }


    &__pretitle {
      margin: 0;
      font-family: $font-family-en-accent;
      font-size: 12px;
      font-weight: 700;
      line-height: lh(12, 18);
      letter-spacing: .15em;

      & + .cmp-teaser__title {
        margin-top: 6px;
      }
    }


    &__title {
      font-size: 26px;
      font-weight: 700;
      line-height: lh(26, 36);
      letter-spacing: .1em;
    }


    &__description {
      margin-top: 16px;
      font-size: 14px;
      line-height: lh(14, 28);

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
