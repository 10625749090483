.cmp-toc__content--display-horizontal {
  .cmp-toc__content {
    padding: 30px 16px;
    background-color: $color-accent;
  
    .cmp-container--main-sub & {
      @include media-breakpoint-down(md) {
        margin-left: -30px;
        margin-right: -30px;
      }
    }
  
    @include media-breakpoint-up(md) {
      border-radius: 10px;
    }
  
    ul {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 16px;
      padding: 0;
      margin: 0;
      list-style-type: none;
  
      li {
        font-size: 14px;
        line-height: lh(14, 21);
  
        a {
          display: block;
          position: relative;
          padding-left: 18px;
          color: $color-text;
          text-decoration: none;
  
          &:hover,
          &:active,
          &:focus {
            color: $color-cosmo-blue;
            text-decoration: underline;
          }
  
          &:visited {
            color: #7f7f7f;
          }
  
          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 2px;
            width: 10px;
            height: 6px;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.312%22%20height%3D%225.356%22%20viewBox%3D%220%200%209.312%205.356%22%3E%3Cpath%20d%3D%22M.205%2C9.107a.7.7%2C0%2C0%2C1%2C0-.989L3.667%2C4.657.205%2C1.194A.7.7%2C0%2C0%2C1%2C1.2.205L5.151%2C4.161a.7.7%2C0%2C0%2C1%2C0%2C.99l-.022.021L1.193%2C9.107a.7.7%2C0%2C0%2C1-.988%2C0Z%22%20transform%3D%22translate(9.312)%20rotate(90)%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fsvg%3E');
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }  
}
