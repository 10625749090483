.cmp-image--margin-bottom-zero {
  .cmp-image {
    margin-bottom: 0;
  }
}


.cmp-image--margin-bottom-m {
  .cmp-image {
    margin-bottom: 16px;
  }
}


.cmp-image--margin-bottom-l {
  .cmp-image {
    margin-bottom: 32px;
  }
}
