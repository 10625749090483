@mixin cmp-container--layout-column($column-width, $gap, $content-width: $content-width) {
  > .cmp-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      row-gap: #{$gap}px;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      column-gap: #{$gap}px;
    }

    > * {
      @include media-breakpoint-up(md) {
        width: calc($column-width / $content-width * 100%);
      }
    }
  }
}


// column1
.cmp-container--layout-column1-s {
  > * {
    @include media-breakpoint-up(md) {
      width: calc(860 / $content-width * 100%);
      margin-left: auto;
      margin-right: auto;
    }
  }
}


// column2
.cmp-container--layout-column2-l {
  @include cmp-container--layout-column(564, 48);
}

.cmp-container--layout-column2-m {
  @include cmp-container--layout-column(467, 48);
}

.cmp-container--layout-column2-s {
  @include cmp-container--layout-column(360, 48);
}

.cmp-container--layout-column2-xs {
  @include cmp-container--layout-column(280, 16);
}


// column3
.cmp-container--layout-column3-l {
  @include cmp-container--layout-column(360, 48);
}

.cmp-container--layout-column3-m {
  @include cmp-container--layout-column(280, 16);
}


// column4
.cmp-container--layout-column4 {
  @include cmp-container--layout-column(280, 16);
}
