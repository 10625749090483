.cmp-news-index {
  padding: 0;
  margin: 0;
  list-style: none;

  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #CFD8DC;


  &__item {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #CFD8DC;

    opacity: 1;
    visibility: visible;
    height: auto;
    transition:
      border-bottom .3s ease-out,
      opacity     .1s linear,
      visibility  .1s linear;

    * {
      opacity: 1;
      visibility: visible;
      height: auto;

      transition:
        padding-top    .3s ease-out,
        padding-bottom .3s ease-out,
        margin-top     .3s ease-out,
        margin-bottom  .3s ease-out,
        line-height    .3s ease-out,
        opacity        .1s linear .1s,
        visibility     .1s linear .1s;
    }

    &.cmp-news-index__hidden,
    &.cmp-news-index__hidden * {
      opacity: 0;
      visibility: hidden;
      height: 0;
      border-bottom: 0;
    }

    &.cmp-news-index__hidden * {
      opacity: 0;
      visibility: hidden;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 0;
    }


    &-link {
      display: grid;
      grid-template-columns: max-content 1fr;
      width: 100%;
      row-gap: 16px;
      padding: 24px;
      text-decoration: none;
      @include media-breakpoint-down(md) {
        padding: 24px 0;
      }

      &.pdf .cmp-news-index__item-description {
        &::before {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3e%3cg fill='%23BA2B12' clip-path='url(%23a)'%3e%3cpath d='M8.385 2.042h.006l3.567 3.573-.008 6.343-9.908-.008.008-9.908h6.335Zm0-.875H2.05a.885.885 0 0 0-.883.883v9.9c0 .487.396.883.883.883h9.9a.885.885 0 0 0 .883-.883V5.615a.883.883 0 0 0-.26-.625L9.01 1.426a.883.883 0 0 0-.625-.26Z'/%3e%3cpath d='M12.25 6.27h-3.5a1.02 1.02 0 0 1-1.02-1.02v-3.5h.874v3.5c0 .082.064.146.146.146h3.5v.875ZM3.392 9.713v1.205h-.694V7.497h1.225c.787 0 1.28.361 1.28 1.096 0 .735-.487 1.12-1.28 1.12h-.53Zm.432-.586c.481 0 .685-.169.685-.537 0-.326-.204-.504-.685-.504h-.432v1.041h.432Zm2.146 1.791V7.497h1.214c.773 0 1.312.396 1.312 1.487v.446c0 1.085-.54 1.488-1.312 1.488H5.97ZM7.805 9.43v-.446c0-.589-.157-.898-.618-.898h-.522v2.246h.522c.46 0 .618-.312.618-.902ZM11.5 7.497v.589h-1.438v.849h1.29v.586h-1.29v1.397h-.694V7.497H11.5Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h11.667v11.667H0z' transform='translate(1.167 1.167)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
        }
      }

      &.external .cmp-news-index__item-description {
        &::after {
          content: "";
          width: 1.14em;
          height: 1.14em;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.75 8.667c0-.322.261-.584.583-.584H3.99V9.25H2.917v3.5h5v-1.42h1.166v2.003a.583.583 0 0 1-.583.584H2.333a.583.583 0 0 1-.583-.584V8.667Z'/%3e%3cpath d='M5.417 2.333c0-.322.26-.583.583-.583h8c.322 0 .583.261.583.583v7a.583.583 0 0 1-.583.584H6a.583.583 0 0 1-.583-.584v-7Zm1.166.584V8.75h6.834V2.917H6.583Z'/%3e%3c/g%3e%3c/svg%3e");
          background-position: center;
          background-repeat: no-repeat;
          margin-left: 4px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }


    &-date {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding-right: 16px;
      color: #3C3C40;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: normal;
      @include media-breakpoint-down(md) {
        padding-right: 8px;
      }
    }


    &-category {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 8px;

      @include articleTagCategory;
      @include articleTagColor;
    }


    &-description {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      color: $color-text;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: normal;
      display: table;
      
      .cmp-news-index__item-link:hover &,
      .cmp-news-index__item-link:active &,
      .cmp-news-index__item-link:focus & {
        color: $color-cosmo-blue;
        text-decoration: underline;
      }

      &::before {
        content: "";
        width: 1em;
        height: 1em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
        background-position: top 5px center;
        background-repeat: no-repeat;
        background-size: contain;
        border-right: 4px solid transparent;
        display: table-cell;
      }
    }
  }

  &__button {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 42px;
    border-style: solid;
    border-width: 1px;
    border-color: $color-cosmo-blue;
    border-radius: 100vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #fff;
    color: #3c3c40;
    line-height: lh(14, 20);
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      width: calc(216/315 * 100%);
    }

    @include media-breakpoint-up(md) {
      min-width: 216px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 22px;
      width: 10px;
      height: 10px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.994%22%20height%3D%229.994%22%20viewBox%3D%220%200%209.994%209.994%22%3E%3Crect%20width%3D%221.4%22%20height%3D%229.994%22%20rx%3D%220.7%22%20transform%3D%22translate(5.697%209.994)%20rotate(180)%22%20fill%3D%22%2300ad92%22%2F%3E%3Crect%20width%3D%221.4%22%20height%3D%229.994%22%20rx%3D%220.7%22%20transform%3D%22translate(0%205.697)%20rotate(-90)%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fsvg%3E');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:active {
      background-color: $color-cosmo-blue;
      color: #fff;
      cursor: pointer;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: $color-cosmo-blue;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
