.cmp-navigation--display-sitemap {
  .cmp-navigation {
    &__group {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
    &__item {
      list-style: none;
      &--level-0 {
        & > .cmp-navigation__item-link {
          display: block;
          border-top-width: 1px;
          border-top-color: #CFD8DC;
          border-top-style: solid;
          background-color: $color-accent;
          text-decoration: none;
          color: $color-foreground;
          font-weight: 700;
          position: relative;
          @include media-breakpoint-down(md){
            padding-left: 30px;
            padding-right: 50px;
            padding-top: 10.5px;
            padding-bottom: 10.5px;
            font-size: 14px;
            line-height: lh(14, 28.8);
          }
          @include media-breakpoint-up(md){
            padding-left: 162px;
            padding-right: 162px;
            padding-top: 20px;
            padding-bottom: 18px;
            font-size: 18px;
            letter-spacing: 0.1em;
            line-height: lh(18, 26);
          }
          &::before {
            display: block;
            content: "";
            width: 5px;
            height: 9px;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            @include media-breakpoint-down(md){
              top: 20px;
              right: 30px;
            }
            @include media-breakpoint-up(md){
              top: 29px;
              left: 141px;
            }
          }
          &[target="_blank"]{
            @include media-breakpoint-up{
              display: flex;
              align-items: center;
            }
            &::before {
              @include media-breakpoint-down{
                display: none;
              }
            }
            &::after {
              content: "";
              display: block;
              width: 15px;
              height: 13px;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215%22%20height%3D%2213%22%20viewBox%3D%220%200%2015%2013%22%3E%3Cg%20transform%3D%22translate(-715.25%20-9239)%22%3E%3Cg%20transform%3D%22translate(715.25%209244)%22%20fill%3D%22%23f5f7fd%22%20stroke%3D%22%2300ad92%22%20stroke-width%3D%221%22%3E%3Crect%20width%3D%2211%22%20height%3D%228%22%20stroke%3D%22none%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%2F%3E%3C%2Fg%3E%3Cg%20transform%3D%22translate(719.25%209239)%22%20fill%3D%22%23f5f7fd%22%20stroke%3D%22%2300ad92%22%20stroke-width%3D%221%22%3E%3Crect%20width%3D%2211%22%20height%3D%228%22%20stroke%3D%22none%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
              @include media-breakpoint-up{
                margin-top: 5px;
                margin-left: 10px;
              }
              @include media-breakpoint-down{
                border-radius: 0;
                background-color: transparent;
                top: 20px;
              }
            }
          }
          &:hover {
            @include media-breakpoint-up{
              text-decoration: underline;
              color: $color-cosmo-blue;
            }
          }
        }
        & > .cmp-navigation__group {
          @include media-breakpoint-up(md){
            padding-top: 20px;
            padding-bottom: 38px;
          }
        }
        &:nth-of-type(1) {
          & > .cmp-navigation__item-link {
            display: block;
            border-top-style: none;
            background-color: transparent;
            text-decoration: none;
            color: $color-foreground;
            font-weight: 700;
            line-height: lh(18, 26);
            @include media-breakpoint-down(md){
              padding-left: 30px;
              padding-right: 30px;
              padding-top: 17px;
              padding-bottom: 17px;
              font-size: 14px;
              position: relative;
            }
            @include media-breakpoint-up(md){
              padding-left: 132px;
              padding-right: 132px;
              padding-top: 20px;
              padding-bottom: 20px;
              font-size: 18px;
              letter-spacing: 0.1em;
            }
            &::before {
              @include media-breakpoint-down(md){
                display: block;
                content: "";
                width: 5px;
                height: 9px;
                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 22px;
                right: 30px;
              }
              @include media-breakpoint-up(md){
                display: none;
              }
            }
            &:hover {
              @include media-breakpoint-up{
                text-decoration: underline;
                color: $color-cosmo-blue;
              }
            }
          }
        }
      }
      &--level-1 {
        @include media-breakpoint-up(md){
          padding-left: 162px;
          padding-right: 162px;
        }
        & > .cmp-navigation__item-link {
          text-decoration: none;
          color: $color-foreground;
          font-size: 14px;
          line-height: lh(14, 28.8);
          position: relative;
          @include media-breakpoint-down(md){
            display: block;
            padding-left: 30px;
            padding-right: 50px;
            padding-top: 10.5px;
            padding-bottom: 10.5px;
            border-top-width: 1px;
            border-top-color: #CFD8DC;
            border-top-style: solid;
          }
          @include media-breakpoint-up(md){
            letter-spacing: 0.1em;
          }
          &::before {
            display: block;
            content: "";
            width: 5px;
            height: 9px;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            @include media-breakpoint-down(md){
              top: 20px;
              right: 30px;
            }
            @include media-breakpoint-up(md){
              top: 6px;
              left: -20px;
            }
          }
          &:hover {
            @include media-breakpoint-up{
              text-decoration: underline;
              color: $color-cosmo-blue;
            }
          }
        }
      }
      &--level-2,
      &--level-3,
      &--level-4,
      &--level-5,
      &--level-6,
      &--level-7,
      &--level-8,
      &--level-9 {
        @include media-breakpoint-up(md){
          padding-left: 30.5px;
          padding-right: 30.5px;
        }
        & > .cmp-navigation__item-link {
          text-decoration: none;
          color: $color-foreground;
          font-size: 14px;
          line-height: lh(14, 28.8);
          position: relative;
          @include media-breakpoint-down(md){
            display: block;
            padding-left: 58px;
            padding-right: 30px;
            padding-top: 10.5px;
            padding-bottom: 10.5px;
            border-top-width: 1px;
            border-top-color: #CFD8DC;
            border-top-style: solid;
          }
          @include media-breakpoint-up(md){
            letter-spacing: 0.1em;
          }
          &::before {
            display: block;
            content: "";
            width: 5px;
            height: 9px;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            @include media-breakpoint-down(md){
              top: 20px;
              right: 30px;
            }
            @include media-breakpoint-up(md){
              top: 6px;
              left: -20px;
            }
          }
          &:hover {
            @include media-breakpoint-up{
              text-decoration: underline;
              color: $color-cosmo-blue;
            }
          }
        }
      }
    }
  }
}