.cmp-embed--header {
  box-sizing: border-box;
  @media print, screen and (min-width: 1255px){
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding-left: 31px;
    padding-right: 42px;
    background-color: $color-background;
  }
}

.cmp-embed-header-sp {
  @media print, screen and (max-width: 1254px){
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 29.5px;
    padding-bottom: 29.5px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #DCDDE0;
  }
  @media print, screen and (min-width: 1255px){
    display: block;
  }
}
.cmp-embed-header-logo {
  display: block;
  @media print, screen and (max-width: 1254px){
    width: 109px;
  }
  @media print, screen and (min-width: 1255px){
    width: 139px;
  }
  &:hover {
    @media print, screen and (min-width: 1255px) {
      opacity: 0.7;
    }
  }
  img {
    display: block;
    width: 100%;
  }
}
.cmp-embed-header-hamburger {
  @media print, screen and (max-width: 1254px){
    width: 20px;
  }
  @media print, screen and (min-width: 1255px){
    display: none;
  }
  &__line {
    @media print, screen and (max-width: 1254px){
      display: block;
      width: 100%;
      height: 2px;
      background-color: #333333;
    }
    &:nth-of-type(1){
      @media print, screen and (max-width: 1254px){
        margin-bottom: 4px;
        transition: .5s all ease;
      }
    }
    &:nth-of-type(2){
      @media print, screen and (max-width: 1254px){
        margin-bottom: 4px;
        transition: .5s all ease;
      }
    }
    &:nth-of-type(3){
      @media print, screen and (max-width: 1254px){
        width: 50%;
        transition: .5s all ease;
      }
    }

    &.is-active {
      &:nth-of-type(1){
        @media print, screen and (max-width: 1254px){
          margin-bottom: -2px;
          transform: rotate(45deg);
        }
      }
      &:nth-of-type(2){
        @media print, screen and (max-width: 1254px){
          margin-bottom: 0;
          transform: rotate(-45deg);
        }
      }
      &:nth-of-type(3){
        @media print, screen and (max-width: 1254px){
          opacity: 0;
        }
      }
    }
  }
}
.cmp-embed-header-inner {
  width: 100%;
  @media print, screen and (max-width: 1254px){
    display: none;
    padding-bottom: 44px;
    background-color: $color-background;
    position: absolute;
    z-index: 100;
  }
  @media print, screen and (min-width: 1255px) {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
  }
}
.cmp-embed-header-nav {
  &__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    &--level1 {
      @media print, screen and (min-width: 1255px) {
        display: flex;
      }
    }
    &--level2 {
      display: none;
      @media print, screen and (min-width: 1255px) {
        box-sizing: border-box;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 132px;
        padding-right: 132px;
        padding-top: 24px;
        padding-bottom: 30px;
        background-color: $color-accent;
        position: absolute;
        left: 0;
        z-index: 100;
      }
      &.is-active {
        @media print, screen and (min-width: 1255px){
          display: flex;
        }
      }
    }
    &--level3 {
      @media print, screen and (max-width: 1254px) {
        display: none;
      }
      @media print, screen and (min-width: 1255px) {
        margin-top: 23px;
      }
    }
  }
}
.cmp-embed-header-nav-item {
  &--level1 {
    @media print, screen and (min-width: 1255px){
      margin-left: 35px;
    }
  }
  &--level2 {
    @media print, screen and (min-width: 1255px) {
      width: calc(360/1176*100%);
      margin-top: 23px;
    }
    &:nth-of-type(1) {
      @media print, screen and (min-width: 1255px){
        margin-top: 0;
        width: 100%;
      }
    }
    &:nth-of-type(n+5) {
      @media print, screen and (min-width: 1255px){
        margin-top: 45px;
      }
    }
  }
  &--level3 {
    @media print, screen and (min-width: 1255px){
      margin-top: 14px;
    }
    &:nth-of-type(1) {
      @media print, screen and (min-width: 1255px){
        display: none;
      }
    }
  }
  &__link {
    box-sizing: border-box;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 700;
    color: #3c3c40;
    text-decoration: none;
    @media print, screen and (max-width: 1254px) {
      display: block;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #DCDDE0;
      padding-left: 30px;
      padding-right: 60px;
      padding-top: 16.5px;
      padding-bottom: 16.5px;
    }
    @media print, screen and (min-width: 1255px) {
      display: inline-block;
    }
    &--level1 {
      box-sizing: border-box;
      position: relative;
      @media print, screen and (max-width: 1254px) {
        background-color: $color-accent;
      }
      @media print, screen and (min-width: 1255px) {
        padding-right: 21px;
        padding-top: 32px;
        padding-bottom: 32px;
        background-color: $color-background;
      }
      &::before {
        content: "";
        width: 14px;
        height: 2px;
        background-color: $color-cosmo-green;
        position: absolute;
        top: 24px;
        right: 32px;
        transform: rotate(90deg);

        @media print, screen and (max-width: 1254px){
          display: block;
          border-radius: 2px;
          transition: .5s all ease;
        }
        @media print, screen and (min-width: 1255px){
          display: none;
          transition: none;
        }
      }
      &::after {
        content: "";
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        @media print, screen and (max-width: 1254px){
          width: 14px;
          height: 2px;
          border-radius: 2px;
          background-color: $color-cosmo-green;
          top: 24px;
          right: 32px;
        }
        @media print, screen and (min-width: 1255px){
          width: 9px;
          height: 5px;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.312%22%20height%3D%225.356%22%20viewBox%3D%220%200%209.312%205.356%22%3E%3Cpath%20d%3D%22M.205%2C9.107a.7.7%2C0%2C0%2C1%2C0-.989L3.667%2C4.657.205%2C1.194A.7.7%2C0%2C0%2C1%2C1.2.205L5.151%2C4.161a.7.7%2C0%2C0%2C1%2C0%2C.99l-.022.021L1.193%2C9.107a.7.7%2C0%2C0%2C1-.988%2C0Z%22%20transform%3D%22translate(9.312)%20rotate(90)%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fsvg%3E');
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
      &:only-child {
        @media print, screen and (min-width: 1255px){
            padding-right: 0;
        }
        &::before {
          @media print, screen and (max-width: 1254px){
            display: none;
          }
        }
        &::after {
          @media print, screen and (max-width: 1254px){
            width: 5px;
            height: 9px;
            border-radius: 0;
            background-color: transparent;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
            top: 23px;
            right: 36px;
          }
          @media print, screen and (min-width: 1255px){
            display: none;
          }
        }
      }
      &[target="_blank"] {
        @media print, screen and (min-width: 1255px){
          padding-right: 29px;
        }
        &::before{
          display: none;
        }
        &::after{
          display: block;
          width: 15px;
          height: 13px;
          border-radius: 0;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214.999%22%20height%3D%2213.001%22%20viewBox%3D%220%200%2014.999%2013.001%22%3E%3Cg%20transform%3D%22translate(-411.001%20-251)%22%3E%3Cg%20transform%3D%22translate(5342%20-15727)%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M-4920%2C15991h-11v-8h5v2h6v6Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%20-4920.99853515625%2015990.0009765625%20L%20-4920.99853515625%2015986.0009765625%20L%20-4926.99853515625%2015986.0009765625%20L%20-4926.99853515625%2015983.9990234375%20L%20-4929.9990234375%2015983.9990234375%20L%20-4929.9990234375%2015990.0009765625%20L%20-4920.99853515625%2015990.0009765625%20M%20-4919.99853515625%2015991.0009765625%20L%20-4930.9990234375%2015991.0009765625%20L%20-4930.9990234375%2015982.9990234375%20L%20-4925.99853515625%2015982.9990234375%20L%20-4925.99853515625%2015985.0009765625%20L%20-4919.99853515625%2015985.0009765625%20L%20-4919.99853515625%2015991.0009765625%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fg%3E%3Cg%20transform%3D%22translate(415%20251)%22%20fill%3D%22none%22%20stroke%3D%22%2300ad92%22%20stroke-width%3D%221%22%3E%3Crect%20width%3D%2211%22%20height%3D%228%22%20stroke%3D%22none%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
          background-color: transparent;
          @media print, screen and (max-width: 1254px){
            top: 18px;
            right: 33px;
          }
          @media print, screen and (min-width: 1255px){
            top: 50%;
            right: 0;
          }
        }
      }
      &:hover {
        @media print, screen and (min-width: 1255px){
          color: rgba(27,28,29,0.7);
        }
        &::before {
          @media print, screen and (min-width: 1255px){
            content: "";
            display: block;
            width: 100%;
            height: 5px;
            background-color: $color-cosmo-green;
            top: auto;
            bottom: 0;
            right: auto;
            transform: rotate(0);
            opacity: 1;
          }
        }
      }
      &.is-active {
        @media print, screen and (max-width: 1254px){
          background-color: $color-cosmo-blue;
          color: $color-background;

        }
        @media print, screen and (min-width: 1255px){
          background-color: $color-background;
          color: rgba(27,28,29,0.7);
        }
        &::before {
          transform: rotate(0);
          @media print, screen and (max-width: 1254px){
            opacity: 0;
          }
          @media print, screen and (min-width: 1255px){
            content: "";
            display: block;
            width: 100%;
            height: 5px;
            background-color: $color-cosmo-green;
            top: auto;
            bottom: 0;
            right: auto;
          }
        }
      }
    }
    &--level2 {
      position: relative;
      @media print, screen and (min-width: 1255px){
        padding-left: 27px;
        font-size: 16px;
      }
      &::before {
        content: "";
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        @media print, screen and (max-width: 1254px){
          width: 14px;
          height: 2px;
          border-radius: 2px;
          background-color: $color-cosmo-green;
          top: 24px;
          right: 32px;
          transform: rotate(90deg);
          transition: .5s all ease;
        }
        @media print, screen and (min-width: 1255px){
          width: 18px;
          height: 18px;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cg%20transform%3D%22translate(-3%20-3)%22%3E%3Ccircle%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20transform%3D%22translate(3%203)%22%20fill%3D%22%2300ad92%22%2F%3E%3Cpath%20d%3D%22M2.5%2C0%2C5%2C4H0Z%22%20transform%3D%22translate(14.5%209.5)%20rotate(90)%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
          bottom: 0;
          left: 0;
        }
      }
      &::after{
        content: "";
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        @media print, screen and (max-width: 1254px){
          width: 14px;
          height: 2px;
          border-radius: 2px;
          background-color: $color-cosmo-green;
          top: 24px;
          right: 32px;
        }
      }
      &:only-child {
        &::before {
          @media print, screen and (max-width: 1254px){
            display: none;
          }
        }
        &::after {
          @media print, screen and (max-width: 1254px){
            width: 5px;
            height: 9px;
            border-radius: 0;
            background-color: transparent;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
            top: 23px;
            right: 36px;
          }
        }
      }
      &[target="_blank"] {
        @media print, screen and (max-width: 1254px){
          padding-left: 28px;
        }
        &::before{
          @media print, screen and (min-width: 1255px){
            width: 15px;
            height: 13px;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214.999%22%20height%3D%2213.001%22%20viewBox%3D%220%200%2014.999%2013.001%22%3E%3Cg%20transform%3D%22translate(-411.001%20-251)%22%3E%3Cg%20transform%3D%22translate(5342%20-15727)%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M-4920%2C15991h-11v-8h5v2h6v6Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%20-4920.99853515625%2015990.0009765625%20L%20-4920.99853515625%2015986.0009765625%20L%20-4926.99853515625%2015986.0009765625%20L%20-4926.99853515625%2015983.9990234375%20L%20-4929.9990234375%2015983.9990234375%20L%20-4929.9990234375%2015990.0009765625%20L%20-4920.99853515625%2015990.0009765625%20M%20-4919.99853515625%2015991.0009765625%20L%20-4930.9990234375%2015991.0009765625%20L%20-4930.9990234375%2015982.9990234375%20L%20-4925.99853515625%2015982.9990234375%20L%20-4925.99853515625%2015985.0009765625%20L%20-4919.99853515625%2015985.0009765625%20L%20-4919.99853515625%2015991.0009765625%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fg%3E%3Cg%20transform%3D%22translate(415%20251)%22%20fill%3D%22none%22%20stroke%3D%22%2300ad92%22%20stroke-width%3D%221%22%3E%3Crect%20width%3D%2211%22%20height%3D%228%22%20stroke%3D%22none%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
            bottom: 1px;
          }
        }
      }
      &:hover {
        @media print, screen and (min-width: 1255px){
          text-decoration: underline;
          color: $color-cosmo-blue;
        }
      }
      &.is-active {
        @media print, screen and (max-width: 1254px){
          background-color: $color-cosmo-blue;
          color: $color-background;
        }
        &::before {
          transform: rotate(0);
          @media print, screen and (max-width: 1254px){
            opacity: 0;
          }
        }
      }
    }
    &--level2-top {
      &::before {
        @media print, screen and (min-width: 1255px){
          bottom: -1px;
        }
      }
    }
    &--level3 {
      position: relative;
      @media print, screen and (min-width: 1255px) {
        margin-left: 30px;
        padding-left: 21px;
        font-weight: 400;
      }
      &::before {
        content: "";
        display: block;
        width: 5px;
        height: 9px;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        @media print, screen and (max-width: 1254px){
          top: 23px;
          right: 36px;
        }
        @media print, screen and (min-width: 1255px){
          top: 4px;
          left: 0;
        }
      }
      &:hover {
        @media print, screen and (min-width: 1255px){
          text-decoration: underline;
          color: $color-cosmo-blue;
        }
      }
    }
  }
  &__thumbnail {
    @media print, screen and (max-width: 1254px){
      display: none;
    }
    @media print, screen and (min-width: 1255px){
      display: block;
      margin-bottom: 17px;
      margin-left: -27px;
    }

    &-image {
      display: block;
      width: 100%;
    }
  }
}
.cmp-embed-header-search {
  position: relative;
  @media print, screen and (max-width: 1254px) {
    width: 315px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
  @media print, screen and (min-width: 1255px) {
    width: 220px;
    margin-left: 31px;
  }
  &__submit {
    display: block;
    content: "";
    width: 13px;
    height: 13px;
    border: none;
    background-color: transparent;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213.658%22%20height%3D%2213.657%22%20viewBox%3D%220%200%2013.658%2013.657%22%3E%3Cpath%20d%3D%22M42.021-3.565%2C38.686-6.9a5.473%2C5.473%2C0%2C0%2C1-3.116.969%2C5.5%2C5.5%2C0%2C0%2C1-5.5-5.5%2C5.507%2C5.507%2C0%2C0%2C1%2C5.5-5.5%2C5.507%2C5.507%2C0%2C0%2C1%2C5.5%2C5.5%2C5.464%2C5.464%2C0%2C0%2C1-.97%2C3.114l3.333%2C3.334a1%2C1%2C0%2C0%2C1%2C0%2C1.414%2C1%2C1%2C0%2C0%2C1-.707.293A1%2C1%2C0%2C0%2C1%2C42.021-3.565ZM31.905-11.427a3.67%2C3.67%2C0%2C0%2C0%2C3.666%2C3.666%2C3.671%2C3.671%2C0%2C0%2C0%2C3.668-3.666A3.673%2C3.673%2C0%2C0%2C0%2C35.571-15.1%2C3.671%2C3.671%2C0%2C0%2C0%2C31.905-11.427Z%22%20transform%3D%22translate(-30.07%2016.929)%22%20fill%3D%22%23607d8b%22%2F%3E%3C%2Fsvg%3E');
    background-size: 13px auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
  }
  &__input {
    box-sizing: border-box;
    width: 100%;
    padding-right: 38px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 44px;
    border-width: 1px;
    border-style: solid;
    border-color: #CFD8DC;
    outline: none;
    background-color: $color-accent;
    font-family: $font-family;
    font-weight: 400;
    color: #607D8B;
    @media print, screen and (max-width: 1254px){
      height: 44px;
      padding-left: 38px;
      font-size: 16px;
    }
    @media print, screen and (min-width: 1255px){
      height: 40px;
      padding-left: 38px;
      font-size: 14px;
    }
    &::placeholder {
      color: #607D8B;
    }
  }
}
.cmp-embed-header-language {
  @media print, screen and (max-width: 1254px){
    margin-top: 24px;
    padding-left: 30px;
  }
  @media print, screen and (min-width: 1255px){
    margin-left: 25px;
  }
  &__link {
    font-family: $font-family-en-accent;
    font-size: 14px;
    font-weight: 400;
    color: #3c3c40;
    text-decoration: none;

    &--current {
      color: #4A9A8A;
      font-weight: 700;
    }
  }
}
