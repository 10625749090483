.cmp-title--style-h3-line {
  margin-bottom: 32px;
  .cmp-title {
    .cmp-title__text{
      color: #3c3c40;
      position: relative;
      font-weight: 700;
      padding-bottom: calc(16px * (24px / 28px) + 2px); // h2の下padding, font-size: 28px; padding-bottom: 16px を h3の font-size 24 に縮小
      line-height: 1.5;
      font-size: 24px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 2px;
        margin: 0 auto;
        background-color: #00ad92;
        border-radius: 2px;
      }

      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }
  }
}