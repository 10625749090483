@mixin currentIndication {
  background-color: $color-accent;
  &:hover {
    background-color: #EAECF8;
  }
  & + .cmp-navigation__group {
    background-color: #F5F7FD;
  }
}

.cmp-navigation--display-subnav {
  border: 1px solid #CFD8DC;
  border-radius: 10px;
  overflow: hidden;

  &.cmp-navigation--layout-full {
    @include media-breakpoint-down {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .cmp-navigation {
    &__group {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
    &__item {
      list-style: none;
      .cmp-navigation__item-link {
        display: block;
        background-color: $color-background;
        text-decoration: none;
        color: $color-foreground;
        font-size: 16px;
        line-height: lh(16, 24);
        position: relative;
        padding: 12px 8px 12px 16px;
        &:hover {
          background-color: #EAECF8;
          @include media-breakpoint-up(md){
            text-decoration: none;
          }
        }
      }
      &--level-0 {
        & > .cmp-navigation__item-link {
          background-color: #E2E7F9;
          color: #545459;
          font-size: 18px;
          font-weight: 700;
          border-bottom: 2px solid #2C31A6;
          transition: 0.3s;
          @include media-breakpoint-down(md){
            padding-top: 21px;
            padding-bottom: 21px;
          }
          @include media-breakpoint-up(md){
            padding-left: 16px;
            padding-right: 10px + 16px + 4px; // 余白 ＋ アイコン ＋ アイコンとテキストの間の余白
            padding-top: 28px;
            padding-bottom: 28px;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            width: 24px;
            height: 24px;
            margin-top: auto;
            margin-bottom: auto;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M7.043 3.543a1 1 0 0 1 1.414 0l8 8a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414-1.414l7.293-7.293-7.293-7.293a1 1 0 0 1 0-1.414Z' clip-rule='evenodd'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-size: 100%;
            transition: 0.3s;
          }
          &:hover {
            @include media-breakpoint-up(md){
              background-color: #D8DDF4;
              &::before {
                right: 2px;
              }
            }
          }
        }
      }
      &--level-1 {
        border-bottom-width: 1px;
        border-bottom-color: #CFD8DC;
        border-bottom-style: solid;
        &:last-child {
          border-bottom: 0;
        }
        & > .cmp-navigation__group {
          display: none;
        }
        &.cmp-navigation__item--active {
          & > .cmp-navigation__group {
            display: block;
            padding-left: 16px;
          }

          // カレント表現
          .cmp-navigation__item-link {
            &[aria-current^="page"] {
              @include currentIndication();
            }
          }
        }
        // 深い階層（第4階層以降）のページでは親（第3階層）の該当箇所をカレントにする（aria-currentは出力されないが .cmp-navigation__item--active をセレクタにできる）
        .cmp-navigation__item--level-3.cmp-navigation__item--active .cmp-navigation__item-link {
          @include currentIndication();
        }
      }


      &--level-2,
      &--level-3,
      &--level-4,
      &--level-5,
      &--level-6 {
        & > .cmp-navigation__group {
          display: none;
        }
        & > .cmp-navigation__item-link {
          position: relative;
          border-top: 1px solid #CFD8DC;
          border-left: 1px solid #CFD8DC;
          padding-left: 16px;
        }
        &.cmp-navigation__item--active {
          & > .cmp-navigation__group {
            display: block;
            padding-left: 16px;
            border-left: 1px solid #cfd8dc;
            background-color: #ffffff;
          }
        }
      }
      &--level-3,
      &--level-4,
      &--level-5,
      &--level-6 {
        & > .cmp-navigation__item-link {
          border-top: 1px solid #E2E8EA;
          border-left: 1px solid #E2E8EA;
        }
        &.cmp-navigation__item--active {
          & > .cmp-navigation__group {
            border-left: 1px solid #E2E8EA;
          }
        }
      }
      &--level-4,
      &--level-5,
      &--level-6 {
        & > .cmp-navigation__item-link {
          padding-left: 12px;
        }
        &.cmp-navigation__item--active {
          & > .cmp-navigation__group {
            padding-left: 12px;
          }
        }
      }
    }
  }
}