.cmp-title--style-h3 {
  margin-bottom: 32px;
  .cmp-title {
    .cmp-title__text{
      color: #3c3c40;
      line-height: 1.5;
      font-size: 24px;
      font-weight: 700;

      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }
  }
}