@mixin cmp-container--padding-bottom($pc, $sp) {
  > .cmp-container {
    @include media-breakpoint-down(md) {
      padding-bottom: #{$sp}px;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: #{$pc}px;
    }
  }
}


.cmp-container--padding-bottom-xs {
  @include cmp-container--padding-bottom(8, 8);
}

.cmp-container--padding-bottom-s {
  @include cmp-container--padding-bottom(16, 16);
}

.cmp-container--padding-bottom-m {
  @include cmp-container--padding-bottom(32, 32);
}

.cmp-container--padding-bottom-l {
  @include cmp-container--padding-bottom(48, 48);
}

.cmp-container--padding-bottom-ml {
  @include cmp-container--padding-bottom(64, 64);
}

.cmp-container--padding-bottom-ll {
  @include cmp-container--padding-bottom(72, 72);
}

.cmp-container--padding-bottom-xl {
  @include cmp-container--padding-bottom(80, 80);
}

.cmp-container--padding-bottom-xl2 {
  @include cmp-container--padding-bottom(80, 80);
}

.cmp-container--padding-bottom-xxl {
  @include cmp-container--padding-bottom(160, 80);
}