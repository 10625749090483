.cmp-title--style-h2 {
  margin-bottom: 40px;
  .cmp-title {
    .cmp-title__text {
      position: relative;
      padding-top: 16px;
     padding-bottom: calc(16px + 2px);
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 2px;
        margin: 0 auto;
        background-color: #00ad92;
        border-radius: 2px;
      }

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }
    }
  }
}