.link_to_pagetop {
  display: block;
  position: fixed;
	z-index: 10;
  right: 53px;
  bottom: 16px;
  width: auto;
  height: auto;
  overflow: visible;
  transition: opacity .3s;
  -webkit-tap-highlight-color: transparent;

  &.fadedOut {
    opacity: 0;
    pointer-events: none;
  }

  &.stickToTopOfFooter {
    position: absolute;
    top: calc(-48px - 16px);
  }

  .link_to_pagetop__content {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: center center no-repeat;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDQ4IDQ4Ij48ZyBmaWx0ZXI9InVybCgjYSkiPjxyZWN0IHdpZHRoPSI0NiIgaGVpZ2h0PSI0NiIgeD0iMSIgeT0iMSIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjMDBBRDkyIiBzdHJva2Utd2lkdGg9IjIiIHJ4PSIyMyIvPjxnIGNsaXAtcGF0aD0idXJsKCNiKSI+PHBhdGggZmlsbD0iIzAwQUQ5MiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTQuNTY3IDI4LjgyOGExLjA4MyAxLjA4MyAwIDAgMSAwLTEuNTMxbDguNjY3LTguNjY3YTEuMDgzIDEuMDgzIDAgMCAxIDEuNTMyIDBsOC42NjcgOC42NjdhMS4wODMgMS4wODMgMCAxIDEtMS41MzIgMS41MzFMMjQgMjAuOTI4bC03LjkgNy45YTEuMDgzIDEuMDgzIDAgMCAxLTEuNTMzIDBaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L2c+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgyNnYyNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEgMTEpIi8+PC9jbGlwUGF0aD48ZmlsdGVyIGlkPSJhIiB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHg9Ii00IiB5PSItNCIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiPjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+PGZlR2F1c3NpYW5CbHVyIGluPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHN0ZERldmlhdGlvbj0iMiIvPjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl80MDYxXzE3NDYzOSIvPjxmZUJsZW5kIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfNDA2MV8xNzQ2MzkiIG1vZGU9Im5vcm1hbCIgcmVzdWx0PSJzaGFwZSIvPjwvZmlsdGVyPjwvZGVmcz48L3N2Zz4=);
    text-indent: -9999px;
    overflow: hidden;

    @media (hover: hover) {
      &:hover {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDQ4IDQ4Ij48ZyBmaWx0ZXI9InVybCgjYSkiPjxyZWN0IHdpZHRoPSI0NiIgaGVpZ2h0PSI0NiIgeD0iMSIgeT0iMSIgZmlsbD0iI0Y0RjRGQSIgc3Ryb2tlPSIjMDBBRDkyIiBzdHJva2Utd2lkdGg9IjIiIHJ4PSIyMyIvPjxnIGNsaXAtcGF0aD0idXJsKCNiKSI+PHBhdGggZmlsbD0iIzAwQUQ5MiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTQuNTY3IDI4LjgyOGExLjA4MyAxLjA4MyAwIDAgMSAwLTEuNTMxbDguNjY3LTguNjY3YTEuMDgzIDEuMDgzIDAgMCAxIDEuNTMyIDBsOC42NjcgOC42NjdhMS4wODMgMS4wODMgMCAxIDEtMS41MzIgMS41MzFMMjQgMjAuOTI4bC03LjkgNy45YTEuMDgzIDEuMDgzIDAgMCAxLTEuNTMzIDBaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L2c+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgyNnYyNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEgMTEpIi8+PC9jbGlwUGF0aD48ZmlsdGVyIGlkPSJhIiB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHg9Ii00IiB5PSItNCIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiPjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+PGZlR2F1c3NpYW5CbHVyIGluPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHN0ZERldmlhdGlvbj0iMiIvPjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl81Mzg2XzEwOTY3MSIvPjxmZUJsZW5kIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfNTM4Nl8xMDk2NzEiIG1vZGU9Im5vcm1hbCIgcmVzdWx0PSJzaGFwZSIvPjwvZmlsdGVyPjwvZGVmcz48L3N2Zz4=);
      }
    }
    &:active {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDQ4IDQ4Ij48ZyBmaWx0ZXI9InVybCgjYSkiPjxyZWN0IHdpZHRoPSI0NiIgaGVpZ2h0PSI0NiIgeD0iMSIgeT0iMSIgZmlsbD0iI0Y0RjRGQSIgc3Ryb2tlPSIjMDBBRDkyIiBzdHJva2Utd2lkdGg9IjIiIHJ4PSIyMyIvPjxnIGNsaXAtcGF0aD0idXJsKCNiKSI+PHBhdGggZmlsbD0iIzAwQUQ5MiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTQuNTY3IDI4LjgyOGExLjA4MyAxLjA4MyAwIDAgMSAwLTEuNTMxbDguNjY3LTguNjY3YTEuMDgzIDEuMDgzIDAgMCAxIDEuNTMyIDBsOC42NjcgOC42NjdhMS4wODMgMS4wODMgMCAxIDEtMS41MzIgMS41MzFMMjQgMjAuOTI4bC03LjkgNy45YTEuMDgzIDEuMDgzIDAgMCAxLTEuNTMzIDBaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L2c+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgyNnYyNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEgMTEpIi8+PC9jbGlwUGF0aD48ZmlsdGVyIGlkPSJhIiB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHg9Ii00IiB5PSItNCIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiPjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+PGZlR2F1c3NpYW5CbHVyIGluPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHN0ZERldmlhdGlvbj0iMiIvPjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl81Mzg2XzEwOTY3MSIvPjxmZUJsZW5kIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfNTM4Nl8xMDk2NzEiIG1vZGU9Im5vcm1hbCIgcmVzdWx0PSJzaGFwZSIvPjwvZmlsdGVyPjwvZGVmcz48L3N2Zz4=);
    }
  }
}
@include media-breakpoint-down(md--header) {
  .link_to_pagetop {
    right: 8px;
  }
}

.gfooter {
  position: relative;
  border-top: 1px solid #CFD8DC;

  * {
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  .gfooter__content {
    background-color: #fff;
  }

  .gfooter__main-nav {
    padding: 32px calc(53px + 16px) 0;

    .gfooter__main-nav__content {
      display: flex;
      column-gap: 36px;

      a.gfooter__main-nav__item__content {
        &:hover {
          text-decoration: underline;
          color: #2C31A6;
        }
      }

      span.gfooter__main-nav__item__content {
        opacity: .4;
      }

      .gfooter__main-nav__item__content {
        font-weight: 700;
        color: #3C3C40;
        line-height: 1.5;
        font-size: 14px;

        &::after {
          transform: translateY(4px);
          margin-left: 4px;
          content: '';
          display: inline-block;
          width: 14px;
          height: 14px;
          background: center center no-repeat;
          background-size: 14px;
          background-image: none;
        }
        &[target="_blank"]::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.75 8.667c0-.322.261-.584.583-.584H3.99V9.25H2.917v3.5h5v-1.42h1.166v2.003a.583.583 0 0 1-.583.584H2.333a.583.583 0 0 1-.583-.584V8.667Z'/%3e%3cpath d='M5.417 2.333c0-.322.26-.583.583-.583h8c.322 0 .583.261.583.583v7a.583.583 0 0 1-.583.584H6a.583.583 0 0 1-.583-.584v-7Zm1.166.584V8.75h6.834V2.917H6.583Z'/%3e%3c/g%3e%3c/svg%3e");
        }
      }
    }
  }
  @include media-breakpoint-down(md--header) {
    .gfooter__main-nav {
        padding: 32px 16px 0;

      .gfooter__main-nav__content {
        flex-direction: column;
        column-gap: 0;
        row-gap: 24px;
      }
    }
  }

  .gfooter__sub-nav {
    margin-top: 32px;
    padding: 0  calc(53px + 16px) 0;
    font-size: 12px;

    .gfooter__sub-nav__content {
      display: flex;
      column-gap: 28px;
    }

    a.gfooter__sub-nav__item__content {
      &:hover {
        text-decoration: underline;
        color: #2C31A6;
      }
    }

    span.gfooter__sub-nav__item__content {
      opacity: .4;
    }

    .gfooter__sub-nav__item__content {
      color: #3c3c40;
      line-height: 1.5;
    }
  }
  @include media-breakpoint-down(md--header) {
    .gfooter__sub-nav {
      border-top: 1px solid #CFD8DC;
      padding: 32px 16px 0;

      .gfooter__sub-nav__content {
        margin: auto;

        flex-wrap: wrap;
        justify-content: center;
        column-gap: 24px;
        row-gap: 24px;
      }
    }
  }

  .gfooter__trailer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    border-top: 1px solid #CFD8DC;
    padding: 24px calc(53px + 16px);
  }
  @include media-breakpoint-down(md--header) {
    .gfooter__trailer {
      margin-top: 32px;

      flex-direction: column;
      row-gap: 24px;
    }
  }

  .gfooter__logo-corp {
    margin: 0;

    .gfooter__logo-corp__content {
      display: block;
      width: 100px;

      > img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

  }
  .gfooter__copyright {
    margin: 0 0 0 auto;
    font-size: 10px;
    color: #7F7F7F;
  }
  @include media-breakpoint-down(md--header) {
    .gfooter__copyright {
      margin: 0;
    }
  }
}
