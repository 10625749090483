.cmp-teaser--style-hero-home {
  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;


    &__image {
      z-index: 1;
      width: 100%;
    }


    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      z-index: 2;

      @include media-breakpoint-down(md) {
        padding-left: 32px;
        padding-right: 32px;
      }

      @include media-breakpoint-up(md) {
        padding-left: calc(162 / 1440 * 100%);
        padding-right: calc(162 / 1440 * 100%);
      }
    }


    &__pretitle {
      color: $color-cosmo-blue;
      font-family: $font-family-en-accent;
      font-weight: 700;
      letter-spacing: .15em;
      word-break: break-word;
      overflow-wrap: break-word;

      @include media-breakpoint-down(md) {
        margin-top: 42px;
        margin-bottom: 0;
        font-size: 32px;
        line-height: lh(32, 39);
      }

      @include media-breakpoint-up(md) {
        width: 557px;
        margin-top: 120px;
        margin-bottom: 0;
        font-size: 64px;
        line-height: lh(64, 78);
      }
    }


    &__title {
      color: #3c3c40;
      letter-spacing: .2em;
      text-decoration: none;
      word-break: break-word;
      overflow-wrap: break-word;

      @include media-breakpoint-down(md) {
        margin-top: 8px;
        font-size: 17px;
        line-height: lh(17, 24);
      }

      @include media-breakpoint-up(md) {
        margin-top: 22px;
        font-size: 34px;
        line-height: lh(34, 48);
      }
    }


    &__description {
      color: #3c3c40;
      letter-spacing: .1em;
      word-break: break-word;
      overflow-wrap: break-word;

      @include media-breakpoint-down(md) {
        margin-top: 21px;
        font-size: 16px;
        line-height: lh(16, 28);
      }

      @include media-breakpoint-up(md) {
        margin-top: 30px;
        font-size: 16px;
        line-height: lh(16, 32);
      }

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
