.cmp-embed--footer {
  background-color: $color-accent;
  position: relative;

  @media print, screen and (max-width: 1254px) {
    padding-top: 33px;
    padding-bottom: 28px;
  }
  @media print, screen and (min-width: 1255px) {
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: #ECEFF1;
    padding-top: 80px;
    padding-bottom: 82px;
  }
}

.cmp-embed-footer-scrolltop {
  display: none;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 100;
  @media print, screen and (max-width: 1254px){
    bottom: 15px;
    right: 7.5px;
  }
  @media print, screen and (min-width: 1255px){
    right: 120px;
    bottom: 60px;
  }
  &.is-absolute {
    position: absolute;
    top: -25px;
    bottom: auto;
  }
  &:hover {
    @media print, screen and (min-width: 1255px) {
      opacity: 0.7;
    }
  }
  &__button {
    &-image {
      display: block;
      width: 100%;
    }
  }
}

.cmp-embed-footer-nav {
  &__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    &--level1 {
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: #DCDDE0;
      @media print, screen and (min-width: 1255px) {
        display: flex;
        justify-content: space-between;
        max-width: 1164px;
        padding-left: 138px;
        // padding-left: clamp(0px, 9.58vw, 138px);
        padding-right: 138px;
        // padding-right: clamp(0px, 9.58vw, 138px);
        border-top-style: none;
      }
    }
    &--level2 {
      display: none;
      @media print, screen and (min-width: 1255px) {
        display: block;
        margin-top: 23px;
      }
    }
  }
}

.cmp-embed-footer-nav-item {
  list-style: none;
  &--level1 {
    font-size: 14px;
    font-weight: 700;
  }
  &--level2 {
    @media print, screen and (min-width: 1255px) {
      display: block;
    }
    &:nth-of-type(1) {
      display: block;
      @media print, screen and (min-width: 1255px) {
        display: none;
      }
    }
    &:nth-of-type(n+3) {
      @media print, screen and (min-width: 1255px) {
        margin-top: 21px;
      }
    }
  }

  &__link {
    box-sizing: border-box;
    font-family: $font-family;
    color: #3c3c40;
    text-decoration: none;
    position: relative;
    @media print, screen and (max-width: 1254px) {
      display: block;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #DCDDE0;
      padding-left: 30px;
      padding-right: 60px;
      padding-top: 16px;
      padding-bottom: 17px;
    }
    @media print, screen and (min-width: 1255px) {
      display: inline-block;
    }
    &--level1 {
      font-weight: 700;
      @media print, screen and (min-width: 1255px) {
        background-color: $color-accent;
        font-size: 14px;
      }
      @media print, screen and (min-width: 1255px) {
        font-size: 16px;
      }
      &::before {
        @media print, screen and (max-width: 1254px){
          content: "";
          display: block;
          width: 14px;
          height: 2px;
          border-radius: 2px;
          background-color: $color-cosmo-green;
          position: absolute;
          top: 24px;
          right: 32px;
          transform: rotate(90deg);
          transition: .5s all ease;
        }
      }
      &::after {
        @media print, screen and (max-width: 1254px){
          content: "";
          display: block;
          width: 14px;
          height: 2px;
          background-color: $color-cosmo-green;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          top: 24px;
          right: 32px;
        }
      }
      &[target="_blank"] {
        @media print, screen and (min-width: 1255px){
          padding-right: 23px;
        }
        &::before{
          display: none;
        }
        &::after{
          content: "";
          display: block;
          width: 15px;
          height: 13px;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214.999%22%20height%3D%2213.001%22%20viewBox%3D%220%200%2014.999%2013.001%22%3E%3Cg%20transform%3D%22translate(-411.001%20-251)%22%3E%3Cg%20transform%3D%22translate(5342%20-15727)%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M-4920%2C15991h-11v-8h5v2h6v6Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%20-4920.99853515625%2015990.0009765625%20L%20-4920.99853515625%2015986.0009765625%20L%20-4926.99853515625%2015986.0009765625%20L%20-4926.99853515625%2015983.9990234375%20L%20-4929.9990234375%2015983.9990234375%20L%20-4929.9990234375%2015990.0009765625%20L%20-4920.99853515625%2015990.0009765625%20M%20-4919.99853515625%2015991.0009765625%20L%20-4930.9990234375%2015991.0009765625%20L%20-4930.9990234375%2015982.9990234375%20L%20-4925.99853515625%2015982.9990234375%20L%20-4925.99853515625%2015985.0009765625%20L%20-4919.99853515625%2015985.0009765625%20L%20-4919.99853515625%2015991.0009765625%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fg%3E%3Cg%20transform%3D%22translate(415%20251)%22%20fill%3D%22none%22%20stroke%3D%22%2300ad92%22%20stroke-width%3D%221%22%3E%3Crect%20width%3D%2211%22%20height%3D%228%22%20stroke%3D%22none%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
          background-color: transparent;
          position: absolute;
          @media print, screen and (max-width: 1254px){
            top: 18px;
            right: 33px;
          }
          @media print, screen and (min-width: 1255px){
            top: 4px;
            right: 0;
          }
        }
      }
      &.is-active {
        @media print, screen and (max-width: 1254px){
          background-color: $color-cosmo-blue;
          color: $color-background;
        }
        &::before {
          @media print, screen and (max-width: 1254px){
            opacity: 0;
            transform: rotate(0);
          }
        }
      }
    }
    &:hover {
      @media print, screen and (min-width: 1255px) {
        text-decoration: underline;
        color: $color-cosmo-blue;
      }
    }
    &--level2 {
      background-color: $color-background;
      font-size: 14px;
      @media print, screen and (max-width: 1254px) {
        font-weight: 700;
      }
      @media print, screen and (min-width: 1255px) {
        padding-left: 24px;
        background-color: transparent;
        font-weight: 400;
      }
      &::before {
        content: "";
        display: block;
        width: 5px;
        height: 9px;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%2300AD92%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%2300AD92%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        @media print, screen and (max-width: 1254px){
          top: 50%;
          right: 36px;
          transform: translateY(-50%);
        }
        @media print, screen and (min-width: 1255px){
          top: 4px;
          left: 9px;
        }
      }
      br {
        @media print, screen and (max-width: 1254px) {
          display: none;
        }
        @media print, screen and (min-width: 1255px) {
          display: inline;
        }
      }
    }
  }
}

.cmp-embed-footer-sub-nav {
  @media print, screen and (min-width: 1255px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 90px;
    padding-left: 162px;
    // padding-left: clamp(0px, 11.25vw, 162px);
    padding-right: 162px;
    // padding-right: clamp(0px, 11.25vw, 162px);
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    @media print, screen and (max-width: 1254px) {
      margin-top: 22px;
      padding-left: 31px;
      padding-right: 31px;
    }
    @media print, screen and (min-width: 1255px) {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.cmp-embed-footer-sub-nav-item {
  list-style: none;
  &:nth-last-of-type(n+2){
    margin-right: 24px;
  }
  &__link {
    text-decoration: none;
    color: #3c3c40;
    font-family: $font-family;
    font-size: 12px;
    @media print, screen and (max-width: 1254px) {
      line-height: lh(12,36);
    }

    &:hover {
      @media print, screen and (min-width: 1255px) {
        text-decoration: underline;
        color: $color-cosmo-blue;
      }
    }
  }
}

.cmp-embed-footer-copy {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #DCDDE0;
  @media print, screen and (max-width: 1254px) {
    margin-top: 22px;
    padding-top: 26px;
  }
  @media print, screen and (min-width: 1255px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    padding-top: 23px;
    padding-left: 165px;
    // padding-left: clamp(0px, 11.46vw, 165px);
    padding-right: 165px;
    // padding-right: clamp(0px, 11.46vw, 165px);
  }
}

.cmp-embed-footer-copy-logo {
  width: 113px;
  @media print, screen and (max-width: 1254px) {
    margin-left: auto;
    margin-right: auto;
  }
  &__link {
    &:hover {
      @media print, screen and (min-width: 1255px) {
        opacity: 0.7;
      }
    }
    &-image{
      display: block;
      width: 100%;
    }
  }
}

.cmp-embed-footer-copy-copyright {
  display: block;
  margin-top: 16px;
  text-align: center;
  color: #7F7F7F;
  font-family: $font-family-en-accent;
  font-size: 8px;
  @media print, screen and (min-width: 1255px) {
    margin-top: 0;
    font-size: 10px;
  }
}
