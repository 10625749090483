@mixin cmp-container--margin-bottom($pc, $sp) {
  > .cmp-container {
    @include media-breakpoint-down(md) {
      margin-bottom: #{$sp}px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: #{$pc}px;
    }
  }
}


.cmp-container--margin-bottom-xs {
  @include cmp-container--margin-bottom(8, 8);
}

.cmp-container--margin-bottom-s {
  @include cmp-container--margin-bottom(16, 16);
}

.cmp-container--margin-bottom-m {
  @include cmp-container--margin-bottom(32, 32);
}

.cmp-container--margin-bottom-l {
  @include cmp-container--margin-bottom(48, 48);
}

.cmp-container--margin-bottom-ml {
  @include cmp-container--margin-bottom(64, 64);
}

.cmp-container--margin-bottom-ll {
  @include cmp-container--margin-bottom(72, 72);
}

.cmp-container--margin-bottom-xl {
  @include cmp-container--margin-bottom(80, 80);
}

.cmp-container--margin-bottom-xxl {
  @include cmp-container--margin-bottom(160, 80);
}