.cmp-accordion--layout-full {
  .cmp-accordion {
    
    &__item {
      border-radius: 15px;
      border: 1px solid #CFD8DC;
      overflow: hidden;
      .cmp-accordion__header {
        .cmp-accordion__button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 0;
          padding-right: 0;
          border: none;
          background-color: $color-accent;
          @include media-breakpoint-down{
            padding-left: 30px;
            padding-right: 21px;
            padding-top: 15px;
            padding-bottom: 15px;
          }
          @include media-breakpoint-up{
            padding: 16px 32px;
          }
          &:hover {
            @include media-breakpoint-up{
              text-decoration: none;
              color: $color-cosmo-blue;
              cursor: pointer;
              background-color: #F5F7FD;
            }
            .cmp-accordion__icon {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3crect width='48' height='48' fill='white' rx='24'/%3e%3cg fill='%2300AD92' clip-path='url(%23a)'%3e%3cpath d='M34 25.333H14.667c-.734 0-1.334-.6-1.334-1.333s.6-1.333 1.334-1.333H34c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333Z'/%3e%3cpath d='M24 34.667c-.733 0-1.333-.6-1.333-1.334V14.667c0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334v18.666c0 .734-.6 1.334-1.333 1.334Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h32v32H0z' transform='translate(8 8)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
            }
          }
          .cmp-accordion__title {
            box-sizing: border-box;
            max-width: 1576px;
            width: calc(100% - 14px);
            padding-right: 14px;
            font-weight: 700;
            letter-spacing: 0.1em;
            @include media-breakpoint-down(md){
              font-size: 16px;
              line-height: lh(16,24);
            }
            @include media-breakpoint-up(md){
              font-size: 18px;
              line-height: lh(18,27);
            }
          }
          .cmp-accordion__icon {
            display: block;
            width: 32px;
            height: 32px;
            position: relative;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3cg fill='%2300AD92' clip-path='url(%23a)'%3e%3cpath d='M34 25.333H14.667c-.734 0-1.334-.6-1.334-1.333s.6-1.333 1.334-1.333H34c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333Z'/%3e%3cpath d='M24 34.667c-.733 0-1.333-.6-1.333-1.334V14.667c0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334v18.666c0 .734-.6 1.334-1.333 1.334Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h32v32H0z' transform='translate(8 8)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-size: contain;
          }
          &--expanded {
            .cmp-accordion__icon{
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3cpath fill='%2300AD92' d='M34.667 25.333H14c-.733 0-1.333-.6-1.333-1.333s.6-1.333 1.333-1.333h20.667c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333Z'/%3e%3c/svg%3e");
            }
            &:hover {
              .cmp-accordion__icon {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3crect width='48' height='48' fill='white' rx='24'/%3e%3cpath fill='%2300AD92' d='M34.667 25.333H14c-.733 0-1.333-.6-1.333-1.333s.6-1.333 1.333-1.333h20.667c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333Z'/%3e%3c/svg%3e");
              }
            }
          }
        }
      }
      .cmp-accordion__panel {
        @include media-breakpoint-down{
          padding: 24px;
        }
        @include media-breakpoint-up{
          margin: auto;
          padding: 24px 72px;
          max-width: 1176px;
        }
      }
    }
  }
}