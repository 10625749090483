.ceh-alert {
  $root: &;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  background-color: #F5F7FD;
  border-radius: 10px;
  box-sizing: border-box;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }

  &__icon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.662 25.5529L17.6873 4.81221C17.0601 3.72579 15.492 3.72579 14.8647 4.81221L2.89007 25.5529C2.26282 26.6394 3.04688 27.9974 4.30137 27.9974H28.2507C29.5052 27.9974 30.2893 26.6394 29.662 25.5529ZM18.842 4.14554C17.7016 2.17023 14.8505 2.17024 13.71 4.14554L1.73537 24.8863C0.594922 26.8616 2.02048 29.3307 4.30137 29.3307H28.2507C30.5316 29.3307 31.9572 26.8616 30.8167 24.8863L18.842 4.14554Z' fill='%237F7F7F'/%3E%3Cpath d='M17.0234 19.9974H15.642C15.099 19.9974 14.666 19.557 14.666 19.0203V10.3362C14.666 9.41411 15.4083 8.66406 16.3292 8.66406C17.2502 8.66406 17.9993 9.41411 17.9993 10.3362V19.0203C17.9993 19.557 17.5595 19.9974 17.0234 19.9974Z' fill='%237F7F7F'/%3E%3Cpath d='M16.3333 25.9948C17.622 25.9948 18.6667 24.9501 18.6667 23.6615C18.6667 22.3728 17.622 21.3281 16.3333 21.3281C15.0447 21.3281 14 22.3728 14 23.6615C14 24.9501 15.0447 25.9948 16.3333 25.9948Z' fill='%237F7F7F'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include media-breakpoint-down(md) {
      width: 24px;
      height: 24px;
      margin-right: 0;
    }
  }

  &__list {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      margin-top: 8px;
    }
  }

  &__item {
    list-style-type: none;
    font-size: 14px;
    line-height: 1.5;
    color: #3C3C40;
    & + & {
      margin-top: 8px;
    }
  }

  & a {
    display: table;
    &:link,
    &:visited {
      color: #3C3C40;
      text-decoration: none;
    }
    &:hover {
      color: #2C31A6;
      text-decoration: underline;
    }
    &::before {
      content: "";
      width: 1em;
      height: 1em;
      padding-right: 4px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
      background-position: top 5px left;
      background-repeat: no-repeat;
      background-size: 1em;
      display: table-cell;
    }
  }

  & a[target="_blank"] {
    &::after {
      content: "";
      width: 1.14em;
      height: 1.14em;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.75 8.667c0-.322.261-.584.583-.584H3.99V9.25H2.917v3.5h5v-1.42h1.166v2.003a.583.583 0 0 1-.583.584H2.333a.583.583 0 0 1-.583-.584V8.667Z'/%3e%3cpath d='M5.417 2.333c0-.322.26-.583.583-.583h8c.322 0 .583.261.583.583v7a.583.583 0 0 1-.583.584H6a.583.583 0 0 1-.583-.584v-7Zm1.166.584V8.75h6.834V2.917H6.583Z'/%3e%3c/g%3e%3c/svg%3e");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  & a[href$=".pdf"] {
    &::before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3e%3cg fill='%23BA2B12' clip-path='url(%23a)'%3e%3cpath d='M8.385 2.042h.006l3.567 3.573-.008 6.343-9.908-.008.008-9.908h6.335Zm0-.875H2.05a.885.885 0 0 0-.883.883v9.9c0 .487.396.883.883.883h9.9a.885.885 0 0 0 .883-.883V5.615a.883.883 0 0 0-.26-.625L9.01 1.426a.883.883 0 0 0-.625-.26Z'/%3e%3cpath d='M12.25 6.27h-3.5a1.02 1.02 0 0 1-1.02-1.02v-3.5h.874v3.5c0 .082.064.146.146.146h3.5v.875ZM3.392 9.713v1.205h-.694V7.497h1.225c.787 0 1.28.361 1.28 1.096 0 .735-.487 1.12-1.28 1.12h-.53Zm.432-.586c.481 0 .685-.169.685-.537 0-.326-.204-.504-.685-.504h-.432v1.041h.432Zm2.146 1.791V7.497h1.214c.773 0 1.312.396 1.312 1.487v.446c0 1.085-.54 1.488-1.312 1.488H5.97ZM7.805 9.43v-.446c0-.589-.157-.898-.618-.898h-.522v2.246h.522c.46 0 .618-.312.618-.902ZM11.5 7.497v.589h-1.438v.849h1.29v.586h-1.29v1.397h-.694V7.497H11.5Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h11.667v11.667H0z' transform='translate(1.167 1.167)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    }
  }
  & a[href$=".xls"],
  & a[href$=".xlsx"],
  & a[href$=".zip"] {
    &::before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3cg fill='%2300AD92' clip-path='url(%23a)'%3e%3cpath d='M28.75 7h.02L41 19.25 40.97 41 7 40.97 7.03 7h21.72Zm0-3H7.03C5.36 4 4 5.36 4 7.03v33.94C4 42.64 5.36 44 7.03 44h33.94c1.67 0 3.03-1.36 3.03-3.03V19.25c0-.8-.32-1.57-.89-2.14L30.89 4.89c-.57-.57-1.34-.89-2.14-.89Z'/%3e%3cpath d='M42 21.5H30c-1.93 0-3.5-1.57-3.5-3.5V6h3v12c0 .28.22.5.5.5h12v3Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h40v40H0z' transform='translate(4 4)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    }
  }

  @include ceh-margin-bottom('&');
}