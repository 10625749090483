.cmp-button--style-anchor {
  .cmp-button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 44px;
    border-radius: 10px;
    background-color: $color-accent;
    color: #3C3C40;
    text-decoration: none;
    transition: 0.5s ease;

    &[type='button'] {
      width: 100%;
      padding: 0;
      border: 0;
      background-color: $color-accent;
    }

    &:active,
    &:focus {
      background-color: $color-accent;
      color: #3C3C40;
      cursor: pointer;
    }

    &:hover {
      background-color: #EAECF8;
    }


    &__text {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      padding-right: 32px;
      padding-top: 2px;
      padding-bottom: 4px;
      line-height: lh(14, 20);
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }
}
