.cmp-container--layout-media-image-left {
  > .cmp-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      row-gap: 16px;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      column-gap: 48px;
    }

    > *:first-child {
      @include media-breakpoint-up(md) {
        width: calc(360 / $content-width * 100%);
      }
    }

    > *:last-child {
      @include media-breakpoint-up(md) {
        width: calc(768 / $content-width * 100%);
      }
    }
  }
}


.cmp-container--layout-media-image-right {
  > .cmp-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      row-gap: 16px;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      column-gap: 48px;
    }

    > *:first-child {
      @include media-breakpoint-up(md) {
        width: calc(768 / $content-width * 100%);
      }
    }

    > *:last-child {
      @include media-breakpoint-up(md) {
        width: calc(360 / $content-width * 100%);
      }
    }
  }
}


.cmp-container--layout-media-image-right-2 {
  > .cmp-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      row-gap: 16px;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      column-gap: 48px;
    }

    > *:first-child {
      @include media-breakpoint-up(md) {
        width: calc(868 / $content-width * 100%);
      }
    }

    > *:last-child {
      @include media-breakpoint-up(md) {
        width: calc(260 / $content-width * 100%);
      }
    }
  }
}
