@mixin cmp-container--padding-lr($pc, $sp) {
  > .cmp-container {
    @include media-breakpoint-down(md) {
      padding-left: #{$sp}px;
      padding-right: #{$sp}px;
    }

    @include media-breakpoint-up(md) {
      padding-left: #{$pc}px;
      padding-right: #{$pc}px;
    }
  }
}


.cmp-container--padding-lr-xs {
  @include cmp-container--padding-lr(8, 8);
}

.cmp-container--padding-lr-s {
  @include cmp-container--padding-lr(16, 16);
}

.cmp-container--padding-lr-m {
  @include cmp-container--padding-lr(32, 32);
}

.cmp-container--padding-lr-l {
  @include cmp-container--padding-lr(48, 48);
}

.cmp-container--padding-lr-xl {
  @include cmp-container--padding-lr(80, 80);
}

.cmp-container--padding-lr-xxl {
  @include cmp-container--padding-lr(160, 80);
}
