.cmp-title--style-accent {
  margin-bottom: 12px;
  .cmp-title {
    .cmp-title__text {
      letter-spacing: 0.1em;
      line-height: lh(16, 32);
      color: #BA2B12;
      font-size: 16px;
      font-weight: 700;
    }
  }
}