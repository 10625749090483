.ceh-indextitle {
  $root: &;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-wrap: anywhere;
  word-break: auto-phrase;

  &#{$root}--has-pagetitle {
    --color-baseRectangle: #fff;
    --color-flatSmoke: rgba(245, 247, 253, 0.6);

    position: relative;
    background-size: cover;
    background-position: center center;

    &::before,
    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0;
      width: calc((100% - (1176px + 120px)) / 2);
      height: 100%;
    }
    &::before {
      left: 0;
      background-color: var(--color-flatSmoke);
      z-index: 0;
    }
    &::after {
      right: 0;
      z-index: 0;
    }

    &.paleBackground {
      --color-flatSmoke: rgba(245, 247, 253, 0.8);

      #{$root}-bg-center::before {
        background: linear-gradient(270deg, rgba(106, 108, 113, 0) 0%, rgba(245, 247, 253, 0.80) 100%);
      }
    }

    #{$root}-bg-center {
      position: relative;
      z-index: 2;
      width: 100%;
      height: auto;
      min-height: 240px;
      margin: 0 auto;
      max-width: calc(1176px + 120px);

      box-sizing: border-box;
      padding: 24px 60px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-down(md) {
        padding: 52px 30px;
        min-height: revert;
      }

      &::before {
        pointer-events: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(245, 247, 253, 0.00) 0%, rgba(245, 247, 253, 0.60) 100%);
      }

      #{$root}__pagetitle,
      #{$root}__pagelead {
        position: relative;
      }
    }

    #{$root}__pagetitle {
      margin: 0;
      width: 80%;
      max-width: 1176px;
      position: relative;
      color: #3C3C40;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: .05em;

      @include media-breakpoint-down(md) {
        font-size: 28px;
        letter-spacing: 1.4px;
      }
    }
    #{$root}__pagelead {
      margin: 24px 0 0;
      width: 80%;
      max-width: 1176px;
      color: #3C3C40;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.8;

      p {
        margin: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: 16px;
        font-size: 14px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &#{$root}--has-pagetitle {
      #{$root}-bg-center {
        padding: 52px 20px;
        min-height: revert;
      }
    }
  }

  &#{$root}--has-sectiontitle {
    padding: 80px 30px 24px;
    margin-bottom: 48px;

    #{$root}__sectiontitle {
      position: relative;
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.5;
      padding-bottom: calc(16px + 3px);
      width: 100%;
      max-width: 540px;

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        border-radius: 1.5px;
        background-color: #00AD92;
      }
    }
    #{$root}__sectionlead {
      margin: 32px auto 0;
      width: 100%;
      max-width: 600px;
      font-size: 16px;
      line-height: 1.8;
      text-align: center;
    }
    #{$root}__button {
      min-width: 176px;
      box-sizing: border-box;
      position: relative;
      display: block;
      margin: 16px auto 24px;
      padding: 13px 32px 13px calc(16px + 14px + 16px);
      transition: background-color 0.5s ease;

      border-radius: 10px;
      border: 1px solid #CFD8DC;
      background: var(--white, #FFF);

      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      color: #3C3C40;

      &:hover {
        background-color: #f4f4fa;
      }

      &::before {
        position: absolute;
        left: 16px;
        top: 1em;
        margin: auto;
        content: "";
        width: 1em;
        height: 1em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 4px;
        display: inline-block;
      }

      &[target="_blank"] {
        &::after {
          content: "";
          width: 1.14em;
          height: 1.14em;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.75 8.667c0-.322.261-.584.583-.584H3.99V9.25H2.917v3.5h5v-1.42h1.166v2.003a.583.583 0 0 1-.583.584H2.333a.583.583 0 0 1-.583-.584V8.667Z'/%3e%3cpath d='M5.417 2.333c0-.322.26-.583.583-.583h8c.322 0 .583.261.583.583v7a.583.583 0 0 1-.583.584H6a.583.583 0 0 1-.583-.584v-7Zm1.166.584V8.75h6.834V2.917H6.583Z'/%3e%3c/g%3e%3c/svg%3e");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 4px;
          display: inline-block;
          vertical-align: middle;
        }
      }
      &[href$=".pdf"] {
        &::before {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3e%3cg fill='%23BA2B12' clip-path='url(%23a)'%3e%3cpath d='M8.385 2.042h.006l3.567 3.573-.008 6.343-9.908-.008.008-9.908h6.335Zm0-.875H2.05a.885.885 0 0 0-.883.883v9.9c0 .487.396.883.883.883h9.9a.885.885 0 0 0 .883-.883V5.615a.883.883 0 0 0-.26-.625L9.01 1.426a.883.883 0 0 0-.625-.26Z'/%3e%3cpath d='M12.25 6.27h-3.5a1.02 1.02 0 0 1-1.02-1.02v-3.5h.874v3.5c0 .082.064.146.146.146h3.5v.875ZM3.392 9.713v1.205h-.694V7.497h1.225c.787 0 1.28.361 1.28 1.096 0 .735-.487 1.12-1.28 1.12h-.53Zm.432-.586c.481 0 .685-.169.685-.537 0-.326-.204-.504-.685-.504h-.432v1.041h.432Zm2.146 1.791V7.497h1.214c.773 0 1.312.396 1.312 1.487v.446c0 1.085-.54 1.488-1.312 1.488H5.97ZM7.805 9.43v-.446c0-.589-.157-.898-.618-.898h-.522v2.246h.522c.46 0 .618-.312.618-.902ZM11.5 7.497v.589h-1.438v.849h1.29v.586h-1.29v1.397h-.694V7.497H11.5Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h11.667v11.667H0z' transform='translate(1.167 1.167)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
        }
      }
      &[href$=".xls"],
      &[href$=".xlsx"],
      &[href$=".zip"] {
        &::before {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3cg fill='%2300AD92' clip-path='url(%23a)'%3e%3cpath d='M28.75 7h.02L41 19.25 40.97 41 7 40.97 7.03 7h21.72Zm0-3H7.03C5.36 4 4 5.36 4 7.03v33.94C4 42.64 5.36 44 7.03 44h33.94c1.67 0 3.03-1.36 3.03-3.03V19.25c0-.8-.32-1.57-.89-2.14L30.89 4.89c-.57-.57-1.34-.89-2.14-.89Z'/%3e%3cpath d='M42 21.5H30c-1.93 0-3.5-1.57-3.5-3.5V6h3v12c0 .28.22.5.5.5h12v3Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h40v40H0z' transform='translate(4 4)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
        }
      }
    }
    @include media-breakpoint-down(md) {
      #{$root}--has-sectiontitle {
        padding: 48px 24px 24px;
      }
      #{$root}__sectiontitle {
        font-size: 28px;
        max-width: 600px;
        font-size: 28px;
      }
      #{$root}__sectionlead {
        margin-top: 24px;
        font-size: 16px;
      }
    }
  }

  margin-bottom: 0;
}
@include ceh-margin-bottom--stepOverride('.ceh-indextitle.ceh-indextitle');
