.cmp-container--bg-color-paleblue {
  > .cmp-container {
    background-color: $color-accent;
  }
}


.cmp-container--bg-color-white {
  > .cmp-container {
    background-color: #fff;
  }
}
