.cmp-text {
  word-wrap: break-word;
  overflow-wrap: break-word;

  p {
    margin-top: 0;
    margin-bottom: 0;
    color: #3C3C40;
    font-size: 16px;
    line-height: 1.8;
  }
  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    color: #3c3c40;
    font-size: 14px;
    line-height: 1.5;
  }
  ul li,
  ol li {
    display: table;
    & + li {
      margin-top: 8px;
    }
    & > ul,
    & > ol {
      margin-top: 8px;
    }
    &::before {
      content: "";
      display: table-cell;
      width: auto;
      padding-right: 8px;
    }
  }

  ul > li {
    &::before {
      width: 1.5em;
      height: 1.5em;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' fill='none' viewBox='0 0 6 6'%3e%3crect width='6' height='6' fill='%237F7F7F' rx='3'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: top 0.5em left 0.5em;
      background-size: 6px;
    }

    & > ul > li:before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7' fill='none' viewBox='0 0 7 7'%3e%3crect width='5' height='5' x='1' y='1' stroke='%237F7F7F' stroke-width='2' rx='2.5'/%3e%3c/svg%3e");
      background-size: 7px;
    }
  }

  ol {
    counter-reset: orderedcount;
  }
  ol > li {
    counter-increment: orderedcount;
    &::before {
      content: counter(orderedcount) ".";
      min-width: 1.5em;
      text-align: right;
    }
  }

  
  a {
    color: $color-link;
    text-decoration: underline;
    line-height: lh(16, 30);
    &:visited {
      color: $color-cosmo-green;
    }
    &:hover {
      color: $color-cosmo-blue;
    }
  }
  table {
    min-width: 100%;
    border: none;
    border-collapse: collapse;

    tbody {
      border-bottom: 1px solid #CFD8DC;

      tr {
        // 見出し行下は太い横罫線 thead不使用となるためヘッダ行かの識別は設置条件によるものとなる
        &:has(th):not(:has(td)) + tr:has(td) th,
        &:has(th):not(:has(td)) + tr:has(td) td {
          border-top-width: 2px;
        }

        th, td {
          border-left: none;
          &[rowspan]:not(:first-child) {
            border-left: 1px solid #ECEFF1;
          }

          border-top: 1px solid #CFD8DC;
          border-right: 1px solid #ECEFF1;

          &:last-child {
            border-right: 1px solid#fff;
          }
        }

        th {
          padding-left: 8px;
          padding-right: 8px;
          padding-top: 8px;
          padding-bottom: 8px;
          background-color: $color-accent;
          color: #3c3c40;
          font-size: 16px;
          font-weight: 600;
          line-height: lh(16, 28);
        }
        td {
          padding-left: 8px;
          padding-right: 8px;
          padding-top: 8px;
          padding-bottom: 8px;
          color: #3c3c40;
          text-align: right;
          font-size: 16px;
          line-height: lh(16, 28);
        }
      }
    }
  }
}