@mixin cmp-teaser--style-card-vertical-normal-colors($background-color) {
  background-color: $background-color;
  border-radius: 10px;
  display: flex;

  .cmp-teaser {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-decoration: none;
    border: 1px solid #CFD8DC;
    border-radius: 10px;
    background-color: #F5F7FD;

    &__link {
      display: flex;
      flex-direction: column;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        .cmp-teaser__title {
          color: #2C31A6;
          text-decoration: none;
        }
      }
    }


    &__image {
      border-radius: 9px 9px 0 0;
      overflow: hidden;
      will-change: transform;
      order: 1;
      .cmp-image__image {
        width: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        vertical-align: bottom;
        transition: transform 0.3s;
        transform-origin: center center;
      }
    }
    &__link:hover .cmp-image__image {
      transform: scale(1.1);
    }


    &__content {
      order: 2;
    }


    &__pretitle {
      display: none;
      margin: 0;
      color: $color-link;
      font-family: $font-family-en-accent;
      font-size: 11px;
      font-weight: 700;
      line-height: lh(11, 14);
      letter-spacing: .15em;
      word-break: break-word;
      overflow-wrap: break-word;
    }


    &__title {
      order: 2;
      position: relative;
      margin-bottom: 0;
      padding: 34px 24px;
      color: #3C3C40;
      font-size: 20px;
      line-height: 1.5;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
      .cmp-teaser__title-link {
        display: block;
        padding-right: 40px;
        text-decoration: none;
        color: #3c3c40;
        
        &::after {
          position: absolute;
          top: 50%;
          right: 24px;
          content: "";
          width: 1.6em;
          height: 1.6em;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          margin-top: -0.8em;
        }
        &:hover {
          color: #2C31A6;
        }
      }
    }
    
    &__link .cmp-teaser__title {
      padding-right: 64px;
      &::after {
        position: absolute;
        top: 50%;
        right: 24px;
        content: "";
        width: 1.6em;
        height: 1.6em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-top: -0.8em;
      }
    }
    &__link:hover .cmp-teaser__title {
      color: #2C31A6;
    }

    &__link[target="_blank"] .cmp-teaser__title {
      &::after {
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.75 8.667c0-.322.261-.584.583-.584H3.99V9.25H2.917v3.5h5v-1.42h1.166v2.003a.583.583 0 0 1-.583.584H2.333a.583.583 0 0 1-.583-.584V8.667Z'/%3e%3cpath d='M5.417 2.333c0-.322.26-.583.583-.583h8c.322 0 .583.261.583.583v7a.583.583 0 0 1-.583.584H6a.583.583 0 0 1-.583-.584v-7Zm1.166.584V8.75h6.834V2.917H6.583Z'/%3e%3c/g%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    a.cmp-teaser__title-link[href$=".pdf"], // title-link と title__link は別物
    a.cmp-teaser__title-link[href$=".xls"],
    a.cmp-teaser__title-link[href$=".xlsx"],
    a.cmp-teaser__title-link[href$=".zip"],
    &__link[href$=".pdf"] .cmp-teaser__title,
    &__link[href$=".xls"] .cmp-teaser__title,
    &__link[href$=".xlsx"] .cmp-teaser__title,
    &__link[href$=".zip"] .cmp-teaser__title {
      &::before {
        content: "";
        width: 1.2em;
        height: 1.2em;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 4px;
        display: inline-block;
        vertical-align: -4px;
      }
    }
    a.cmp-teaser__title-link[href$=".pdf"],
    &__link[href$=".pdf"] .cmp-teaser__title {
      &::before {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3e%3cg fill='%23BA2B12' clip-path='url(%23a)'%3e%3cpath d='M8.385 2.042h.006l3.567 3.573-.008 6.343-9.908-.008.008-9.908h6.335Zm0-.875H2.05a.885.885 0 0 0-.883.883v9.9c0 .487.396.883.883.883h9.9a.885.885 0 0 0 .883-.883V5.615a.883.883 0 0 0-.26-.625L9.01 1.426a.883.883 0 0 0-.625-.26Z'/%3e%3cpath d='M12.25 6.27h-3.5a1.02 1.02 0 0 1-1.02-1.02v-3.5h.874v3.5c0 .082.064.146.146.146h3.5v.875ZM3.392 9.713v1.205h-.694V7.497h1.225c.787 0 1.28.361 1.28 1.096 0 .735-.487 1.12-1.28 1.12h-.53Zm.432-.586c.481 0 .685-.169.685-.537 0-.326-.204-.504-.685-.504h-.432v1.041h.432Zm2.146 1.791V7.497h1.214c.773 0 1.312.396 1.312 1.487v.446c0 1.085-.54 1.488-1.312 1.488H5.97ZM7.805 9.43v-.446c0-.589-.157-.898-.618-.898h-.522v2.246h.522c.46 0 .618-.312.618-.902ZM11.5 7.497v.589h-1.438v.849h1.29v.586h-1.29v1.397h-.694V7.497H11.5Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h11.667v11.667H0z' transform='translate(1.167 1.167)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
      }
    }
  a.cmp-teaser__title-link[href$=".xls"],
  a.cmp-teaser__title-link[href$=".xlsx"],
  a.cmp-teaser__title-link[href$=".zip"],
  &__link[href$=".xls"] .cmp-teaser__title,
  &__link[href$=".xlsx"] .cmp-teaser__title,
  &__link[href$=".zip"] .cmp-teaser__title {
    &::before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3cg fill='%2300AD92' clip-path='url(%23a)'%3e%3cpath d='M28.75 7h.02L41 19.25 40.97 41 7 40.97 7.03 7h21.72Zm0-3H7.03C5.36 4 4 5.36 4 7.03v33.94C4 42.64 5.36 44 7.03 44h33.94c1.67 0 3.03-1.36 3.03-3.03V19.25c0-.8-.32-1.57-.89-2.14L30.89 4.89c-.57-.57-1.34-.89-2.14-.89Z'/%3e%3cpath d='M42 21.5H30c-1.93 0-3.5-1.57-3.5-3.5V6h3v12c0 .28.22.5.5.5h12v3Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h40v40H0z' transform='translate(4 4)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    }
  }


    &__description {
      margin-left: 24px;
      margin-right: 24px;
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid #CFD8DC;

      p {
        color: #3C3C40;
        font-size: 14px;
        line-height: 1.8;
      }

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }


    &__action-container {
      display: grid;
      row-gap: 16px;
      margin-left: 24px;
      margin-right: 24px;
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid #CFD8DC;
    }
    .cmp-teaser__description + .cmp-teaser__action-container {
      border: none;
      padding-top: 0;
    }


    &__action-link {
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
      display: table;

      &:link,
      &:visited {
        color: #3C3C40;
        text-decoration: none;
      }
      &:hover {
        color: #2C31A6;
        text-decoration: underline;
      }
      &::before {
        content: "";
        width: 1em;
        height: 1em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
        background-position: top 5px center;
        background-repeat: no-repeat;
        background-size: contain;
        border-right: 4px solid transparent;
        display: table-cell;
      }
    }

    &__action-link[target="_blank"] {
      &::after {
        content: "";
        width: 1.14em;
        height: 1.14em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.75 8.667c0-.322.261-.584.583-.584H3.99V9.25H2.917v3.5h5v-1.42h1.166v2.003a.583.583 0 0 1-.583.584H2.333a.583.583 0 0 1-.583-.584V8.667Z'/%3e%3cpath d='M5.417 2.333c0-.322.26-.583.583-.583h8c.322 0 .583.261.583.583v7a.583.583 0 0 1-.583.584H6a.583.583 0 0 1-.583-.584v-7Zm1.166.584V8.75h6.834V2.917H6.583Z'/%3e%3c/g%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: 4px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    &__action-link[href$=".pdf"] {
      &::before {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3e%3cg fill='%23BA2B12' clip-path='url(%23a)'%3e%3cpath d='M8.385 2.042h.006l3.567 3.573-.008 6.343-9.908-.008.008-9.908h6.335Zm0-.875H2.05a.885.885 0 0 0-.883.883v9.9c0 .487.396.883.883.883h9.9a.885.885 0 0 0 .883-.883V5.615a.883.883 0 0 0-.26-.625L9.01 1.426a.883.883 0 0 0-.625-.26Z'/%3e%3cpath d='M12.25 6.27h-3.5a1.02 1.02 0 0 1-1.02-1.02v-3.5h.874v3.5c0 .082.064.146.146.146h3.5v.875ZM3.392 9.713v1.205h-.694V7.497h1.225c.787 0 1.28.361 1.28 1.096 0 .735-.487 1.12-1.28 1.12h-.53Zm.432-.586c.481 0 .685-.169.685-.537 0-.326-.204-.504-.685-.504h-.432v1.041h.432Zm2.146 1.791V7.497h1.214c.773 0 1.312.396 1.312 1.487v.446c0 1.085-.54 1.488-1.312 1.488H5.97ZM7.805 9.43v-.446c0-.589-.157-.898-.618-.898h-.522v2.246h.522c.46 0 .618-.312.618-.902ZM11.5 7.497v.589h-1.438v.849h1.29v.586h-1.29v1.397h-.694V7.497H11.5Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h11.667v11.667H0z' transform='translate(1.167 1.167)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
      }
    }
    &__action-link[href$=".xls"],
    &__action-link[href$=".xlsx"],
    &__action-link[href$=".zip"] {
      &::before {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3cg fill='%2300AD92' clip-path='url(%23a)'%3e%3cpath d='M28.75 7h.02L41 19.25 40.97 41 7 40.97 7.03 7h21.72Zm0-3H7.03C5.36 4 4 5.36 4 7.03v33.94C4 42.64 5.36 44 7.03 44h33.94c1.67 0 3.03-1.36 3.03-3.03V19.25c0-.8-.32-1.57-.89-2.14L30.89 4.89c-.57-.57-1.34-.89-2.14-.89Z'/%3e%3cpath d='M42 21.5H30c-1.93 0-3.5-1.57-3.5-3.5V6h3v12c0 .28.22.5.5.5h12v3Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h40v40H0z' transform='translate(4 4)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
      }
    }
  }
}


.cmp-teaser--style-card-vertical-normal-paleblue {
  @include cmp-teaser--style-card-vertical-normal-colors($color-accent);
}


.cmp-teaser--style-card-vertical-normal-white {
  @include cmp-teaser--style-card-vertical-normal-colors($color-background);
}
