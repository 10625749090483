.cmp-carousel {
  &__actions {
    display: flex;
    column-gap: 4px;
    position: absolute;
    bottom: 13px;
    right: 8px;
    z-index: 10;
  }


  &__action {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 0;
    border-width: 0;
    background-color: transparent;
    appearance: none;
    cursor: pointer;
    touch-action: manipulation;


    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);
      background-size: contain;
      background-repeat: no-repeat;
    }


    &-text {
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      width: 1px;
      height: 1px;
      white-space: nowrap;
    }


    &--pause,
    &--play {
      display: none;
    }


    &--previous {
      .cmp-carousel__action-icon {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cg%20transform%3D%22translate(-458)%22%3E%3Crect%20width%3D%2240%22%20height%3D%2240%22%20rx%3D%2220%22%20transform%3D%22translate(458)%22%20fill%3D%22%23f5f7fd%22%2F%3E%3Cg%20transform%3D%22translate(466%208)%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23607d8b%22%20opacity%3D%220%22%2F%3E%3Cpath%20d%3D%22M.293%2C13.02a1%2C1%2C0%2C0%2C1%2C0-1.414l4.95-4.95L.293%2C1.706A1%2C1%2C0%2C0%2C1%2C1.707.293L7.364%2C5.949a1%2C1%2C0%2C0%2C1%2C0%2C1.415L1.707%2C13.02a1%2C1%2C0%2C0%2C1-1.415%2C0Z%22%20transform%3D%22translate(16%2018.656)%20rotate(180)%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
      }

      &:hover,
      &:active,
      &:focus {
        .cmp-carousel__action-icon {
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cg%20transform%3D%22translate(-458)%22%3E%3Crect%20width%3D%2240%22%20height%3D%2240%22%20rx%3D%2220%22%20transform%3D%22translate(458)%22%20fill%3D%22%232C31A6%22%2F%3E%3Cg%20transform%3D%22translate(466%208)%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23ffffff%22%20opacity%3D%220%22%2F%3E%3Cpath%20d%3D%22M.293%2C13.02a1%2C1%2C0%2C0%2C1%2C0-1.414l4.95-4.95L.293%2C1.706A1%2C1%2C0%2C0%2C1%2C1.707.293L7.364%2C5.949a1%2C1%2C0%2C0%2C1%2C0%2C1.415L1.707%2C13.02a1%2C1%2C0%2C0%2C1-1.415%2C0Z%22%20transform%3D%22translate(16%2018.656)%20rotate(180)%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        }
      }
    }


    &--next {
      .cmp-carousel__action-icon {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cg%20transform%3D%22translate(-535)%22%3E%3Crect%20width%3D%2240%22%20height%3D%2240%22%20rx%3D%2220%22%20transform%3D%22translate(535)%22%20fill%3D%22%23f5f7fd%22%2F%3E%3Cg%20transform%3D%22translate(543%208)%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23607d8b%22%20opacity%3D%220%22%2F%3E%3Cpath%20d%3D%22M.293%2C13.02a1%2C1%2C0%2C0%2C1%2C0-1.414l4.95-4.95L.293%2C1.707A1%2C1%2C0%2C0%2C1%2C1.707.293L7.364%2C5.95a1%2C1%2C0%2C0%2C1%2C0%2C1.414L1.707%2C13.02a1%2C1%2C0%2C0%2C1-1.414%2C0Z%22%20transform%3D%22translate(8.343%205.343)%22%20fill%3D%22%2300ad92%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
      }

      &:hover,
      &:active,
      &:focus {
        .cmp-carousel__action-icon {
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cg%20transform%3D%22translate(-535)%22%3E%3Crect%20width%3D%2240%22%20height%3D%2240%22%20rx%3D%2220%22%20transform%3D%22translate(535)%22%20fill%3D%22%232C31A6%22%2F%3E%3Cg%20transform%3D%22translate(543%208)%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23ffffff%22%20opacity%3D%220%22%2F%3E%3Cpath%20d%3D%22M.293%2C13.02a1%2C1%2C0%2C0%2C1%2C0-1.414l4.95-4.95L.293%2C1.707A1%2C1%2C0%2C0%2C1%2C1.707.293L7.364%2C5.95a1%2C1%2C0%2C0%2C1%2C0%2C1.414L1.707%2C13.02a1%2C1%2C0%2C0%2C1-1.414%2C0Z%22%20transform%3D%22translate(8.343%205.343)%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        }
      }
    }
  }


  &__indicators {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    row-gap: 6px;

    @include media-breakpoint-down(md) {
      bottom: 30px;
      column-gap: 4px;
    }

    @include media-breakpoint-up(md) {
      bottom: 26px;
      column-gap: 8px;
    }
  }


  &__indicator {
    height: 2px;
    border-radius: 1px;
    margin-left: 0;
    margin-right: 0;
    background-color: #cfd8dc;

    @include media-breakpoint-down(md) {
      width: calc(100 / 375 * 30 * 1vw);
    }

    @include media-breakpoint-up(md) {
      width: 40px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $color-cosmo-blue;
      cursor: pointer;
    }


    &--active {
      background-color: $color-text;
    }
  }
}
