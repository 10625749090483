.cmp-accordion--color-faq {
  .cmp-accordion {
    &__item {
      .cmp-accordion__header {
        .cmp-accordion__title {
          &::before {
            content: "Q";
            text-decoration: none;
            color: #00AD92;
            padding-right: 16px;
          }
        }
      }
      .cmp-text--style-faq {
        padding: 0;
      }
    }
  }
}