.cmp-text--margin-bottom-0px {
  margin-bottom: 0px;
}

.cmp-text--margin-bottom-16px {
  margin-bottom: 16px;
}

.cmp-text--margin-bottom-32px {
  margin-bottom: 32px;
}

.cmp-text--margin-bottom-48px {
  margin-bottom: 48px;
}