.ceh-tabs {
  $root: &;
  width: 100%;

  &__content {
    display: flex;
    width: 100%;
  }

  @include ceh-margin-bottom('&');
}