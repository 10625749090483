// margin-bottom
//
// usage: @include ceh-margin-bottom('.foo');
// 0、16、24、32、40、48、56
//---------------------------------------------------
@mixin ceh-margin-bottom--default($rootSelector) {
  // CEH-Default
  #{$rootSelector} {
    margin-bottom: 48px;
  }
}

@mixin ceh-margin-bottom--stepOverride($rootSelector) {
  // Decralative
  #{$rootSelector}--margin-bottom-none {
    margin-bottom: 0;
  }
  #{$rootSelector}--margin-bottom-s {
    margin-bottom: 16px;
  }
  #{$rootSelector}--margin-bottom-s_plus {
    margin-bottom: 24px;
  }
  #{$rootSelector}--margin-bottom-m {
    margin-bottom: 32px;
  }
  #{$rootSelector}--margin-bottom-m_plus {
    margin-bottom: 40px;
  }
  #{$rootSelector}--margin-bottom-l {
    margin-bottom: 48px;
  }
  #{$rootSelector}--margin-bottom-l_plus {
    margin-bottom: 56px;
  }

  // Imperative
  #{$rootSelector}--margin-bottom-0 {
    margin-bottom: 0;
  }
  #{$rootSelector}--margin-bottom-16px {
    margin-bottom: 16px;
  }
  #{$rootSelector}--margin-bottom-24px {
    margin-bottom: 24px;
  }
  #{$rootSelector}--margin-bottom-32px {
    margin-bottom: 32px;
  }
  #{$rootSelector}--margin-bottom-40px {
    margin-bottom: 40px;
  }
  #{$rootSelector}--margin-bottom-48px {
    margin-bottom: 48px;
  }
  #{$rootSelector}--margin-bottom-56px {
    margin-bottom: 56px;
  }

  @include media-breakpoint-down{
    #{$rootSelector}--margin-bottom-0-sp {
      margin-bottom: 0;
    }
    #{$rootSelector}--margin-bottom-16px-sp {
      margin-bottom: 16px;
    }
    #{$rootSelector}--margin-bottom-24px-sp {
      margin-bottom: 24px;
    }
    #{$rootSelector}--margin-bottom-32px-sp {
      margin-bottom: 32px;
    }
    #{$rootSelector}--margin-bottom-40px-sp {
      margin-bottom: 40px;
    }
    #{$rootSelector}--margin-bottom-48px-sp {
      margin-bottom: 48px;
    }
    #{$rootSelector}--margin-bottom-56px-sp {
      margin-bottom: 56px;
    }
  }
}

@mixin ceh-margin-bottom($rootSelector) {
  @include ceh-margin-bottom--default($rootSelector);
  @include ceh-margin-bottom--stepOverride($rootSelector);
}
