.cmp-image {
  &__link {
    &:hover,
    &:active,
    &:focus {
      opacity: .7;
    }
  }

  &__image {
    height: auto;
    vertical-align: bottom;
  }

  &__title {
    display: block;
    margin-top: 8px;
    padding: 0 16px;
    text-align: center;
    color: #3C3C40;
    font-size: 14px;
    line-height: 1.8;
  }
}
