.cmp-title--style-h5 {
  margin-bottom: 24px;
  .cmp-title {
    .cmp-title__text {
      padding-top: 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5;
    }
  }
}