.cmp-text--table-sp-scroll {
  .cmp-text {
    @include media-breakpoint-down(md) {
      margin-right: -30px;
      padding-right: 30px;
      overflow-x: scroll;
    }
    table {
      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }
  }
}