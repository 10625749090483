.cmp-carousel--style-fade {
  .cmp-carousel {
    overflow: hidden;

    &__content {
      @include media-breakpoint-down(md) {
        aspect-ratio: 750/812;
      }

      @include media-breakpoint-up(md) {
        aspect-ratio: 1920/689;
      }
    }


    &__item {
      display: block;
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;

      // Animation
      transition-property: opacity, visibility;
      transition-duration: 1.8s;


      &--active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
