.cmp-button--style-default {
  .cmp-button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 48px;
    border-style: solid;
    border-width: 1px;
    border-color: #CFD8DC;
    border-radius: 10px;
    background-color: #fff;
    color: #3C3C40;
    text-decoration: none;
    transition: 0.5s ease;

    &[type='button'] {
      width: 100%;
      padding: 0;
      background-color: #fff;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: #F4F4FA;
      color: #3C3C40;
      cursor: pointer;
    }


    &__text {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      padding-right: 22px;
      padding-top: 2px;
      padding-bottom: 4px;
      line-height: lh(14, 20);
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }
}
