@mixin cmp-container--border-bottom($border-width, $first-border-width, $last-border-width, $parent-container-width: 1440) {
  > .cmp-container {
    position: relative;
    border-bottom-style: solid;
    border-bottom-color: $color-cosmo-blue;
    border-bottom-width: #{$border-width}px;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      border-bottom-style: solid;
      border-bottom-width: #{$border-width}px;
    }

    &::before {
      left: 0;
      bottom: -#{$border-width}px;
      width: calc($first-border-width / $parent-container-width * 100%);
      border-bottom-color: $color-cosmo-green;
    }

    &::after {
      right: 0;
      bottom: -#{$border-width}px;
      width: calc($last-border-width / $parent-container-width * 100%);
      border-bottom-color: $color-cosmo-red;
    }
  }
}


.cmp-container--border-bottom-normal {
  @include cmp-container--border-bottom(15, 500, 87)
}

.cmp-container--border-bottom-about {
  @include cmp-container--border-bottom(15, 300, 100)
}

.cmp-container--border-bottom-solution {
  @include cmp-container--border-bottom(15, 1053, 87)
}

.cmp-container--border-bottom-service {
  @include cmp-container--border-bottom(15, 87, 1053)
}
