
//== Mixin

// Media queries
//---------------------------------------------------

@mixin media-breakpoint-up($breakpoint: md) {
  @media #{map-get($breakpoints-up, $breakpoint)} {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint: md) {
  @media #{map-get($breakpoints-down, $breakpoint)} {
    @content;
  }
}


// lh: line-height
//----------------------------------------------//
@function lh($fontSize, $lineHeight) {
  @return calc($lineHeight / $fontSize);
}

// font-family switch based on language
//----------------------------------------------//
@mixin font-family-switch {
  :lang(ja) {
      font-family: $font-family;
  }
  :lang(en) {
    font-family: $font-family-en;
  }
}

// category-tags（News, Indexmenu）
//----------------------------------------------//
@mixin articleTagShape {
  box-sizing: border-box;
  min-width: 40px; // 2文字「決算」
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}
@mixin articleTagShape--ForArticleDetail {
  @include articleTagShape;
  padding: 8px;
}

@mixin articleTagCategory($context: articleList) {
  &-name--cosmo-energy-holdings,
  &-name--cosmo-oil,
  &-name--cosmo-oil-marketing,
  &-name--cosmo-energy-exploration-and-production,
  &-name--news,
  &-name--ir,
  &-name--ir-news,
  &-name--sustainability,
  &-name--sc,
  &-name--recruitment,
  &-name--about,
  &-name--mobilityservice,
  &-name--pressrelease,
  &-name--press-release,
  &-name--settlement,
  &-name--irevent,
  &-name--eco-fund {
    @if($context == 'articleDetail') {
      @include articleTagShape--ForArticleDetail;
    }
    @else {
      @include articleTagShape;
    }
  }
}

@mixin articleTagColor {
  &-name--cosmo-energy-holdings {
    border-color: #4aa395;
    color: #005749;
  }

  &-name--cosmo-oil {
    border-color: #688bd0;
    color: #244fa2;
  }

  &-name--cosmo-oil-marketing {
    border-color: #e78472;
    color: #a81900;
  }

  &-name--cosmo-energy-exploration-and-production {
    border-color: #a660b7;
    color: #682479;
  }

  &-name--news {
    border-color: #bf3b3b;
    color: #a81900;
  }

  &-name--ir,
  &-name--ir-news {
    border-color: #4063a7;
    color: #244fa2;
  }

  &-name--sustainability {
    border-color: #629F36;
    color: #466500;
  }

  &-name--sc,
  &-name--eco-fund {
    border-color: #c47900;
    color: #652f00;
  }

  &-name--recruitment {
    border-color: #0077a3;
    color: #00446e;
  }

  &-name--about {
    border-color: #555555;
    color: #3c3c40;
  }

  &-name--mobilityservice {
    border-color: #864e94;
    color: #682479;
  }

  &-name--pressrelease ,
  &-name--press-release {
    border-color: #3c988a;
    color: #005749;
  }

  &-name--settlement {
    border-color: #b9b200;
    color: #696500;
  }

  &-name--irevent {
    border-color: #4063a7;
    color: #244fa2;
  }
}
