.ceh-heading {
  $root: &;
  margin: 0;

  line-height: 1.5;
  overflow-wrap: break-word;

  & &--lv1 {
    position: relative;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    padding: 24px;
    padding-bottom: calc(24px + 3px);
    
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0; right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 64px;
      height: 3px;
      border-radius: 1.5px;
      background-color: #00AD92;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: calc(16px + 3px);
      font-size: 28px;
    }

    margin-bottom: 32px;
  }

  & &--lead {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8;
    max-width: 600px;
    padding: 0 24px;
    word-break: auto-phrase;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  & &--lv1 + &--lead {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 24px;

    margin-bottom: 32px;
  }

  & &--lv2 {
    position: relative;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;

    padding-top: 16px;

    padding-bottom: calc(16px + 2px);

    margin-bottom: 40px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0; right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: #00AD92;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 16px;
      font-size: 24px;
    }
  }

  & &--lv3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;

    padding-top: 16px;

    margin-bottom: 32px;

    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }

  & &--lv4 {
  position: relative;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  padding-left: calc(16px + 3px);

  margin-bottom: 16px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: .125em;
      bottom: 0;
      margin: auto 0;
      width: 3px;
      height: calc(100% - .5em);
      border-radius: 1.5px;
      background-color: #00AD92;
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }

  & &--lv5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;

    padding-top: 16px;

    margin-bottom: 24px;
  }

  & &--lv6 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;

    padding-top: 16px;

    margin-bottom: 24px;
  }

  & &--caption {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;

    padding-top: 16px;

    margin-bottom: 16px;
  }

  @include ceh-margin-bottom--stepOverride('&');
}
