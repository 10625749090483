.cmp-container--main-sub {
  > .cmp-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      row-gap: 48px;
      padding-left: $content-side-space-for-mobile;
      padding-right: $content-side-space-for-mobile;
    }

    @include media-breakpoint-up(md) {
      justify-content: space-between;
      column-gap: 48px;
      max-width: 1176px;
      padding-left: 60px;
      padding-right: 60px;
      margin-left: auto;
      margin-right: auto;
    }

    > *:first-child {
      @include media-breakpoint-up(md) {
        width: calc(868 / $content-width * 100%);
      }
    }

    > *:last-child {
      @include media-breakpoint-up(md) {
        width: calc(260 / $content-width * 100%);
      }
    }
  }
}
