.cmp-text--style-faq {
  padding: 16px 32px;
  .cmp-text {
    p {
      display: table;
      font-size: 16px;
      line-height: 1.8;
      
      &::before {
        display: table-cell;
        width: auto;
        padding-right: 16px;
        content: "A";
        color: #2C31A6;
        font-weight: bold;
      }
    }
  }
}