
//== Defaults

html, body {
  margin: 0;
  color: $color-text;
  font-size: $font-size;

  @include font-family-switch;

  background: $color-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  scroll-padding-top: calc(128px + 16px);
  @include media-breakpoint-down(md--header) {
    scroll-padding-top: calc(81px + 16px);
  }
}

a {
  color: $color-link;
}

p {
  margin-block: 0.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}
main.container {
  @include generate-grid--for-embeded-breadcrumbs(default, $max_col);
}

// Phone breakpoint
@media (max-width: 899px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
  main.container {
    @include generate-grid--for-embeded-breadcrumbs(phone, $max_col);
  }
}

/* News Detail - narrower content width */
html:has([name="template"]):has([content="page-news-detail"]) body main.container.responsivegrid.aem-GridColumn--default--12 {
  @include media-breakpoint-up(md) {
    width: calc(860px + 52px + 52px); // container whole + padding
    float: none;
    margin-left: auto;
    margin-right: auto;

    .cmp-container--main>.cmp-container {
      padding-left: 52px;
      padding-right: 52px;
    }
  }
}

//== especially for <br> - Desktop / Phone Appearance Switch
@include media-breakpoint-down(md) {
  .ceh-desktopOnly {
    display: none;
  }
}
