.cmp-title--style-h1 {
  .cmp-title {
    .cmp-title__text {
      position: relative;
      margin-bottom: 32px;
      padding: 24px 24px 27px;
      font-size: 38px;
      font-weight: 700;
      line-height: 1.5;
      text-align: center;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 64px;
        height: 3px;
        margin: 0 auto;
        background-color: #00ad92;
        border-radius: 1.5px;
      }

      @include media-breakpoint-down(md) {
        padding-bottom: calc(16px + 3px);
        font-size: 28px;
      }
    }
  }
}

// H1下太字リード文
.cmp-title--style-h1 + .text:has([style*="text-align: center"]):has(b) {
  padding-left: $content-side-space-for-mobile;
  padding-right: $content-side-space-for-mobile;
}
