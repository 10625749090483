.cmp-article-title {
  &__category {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    @include articleTagCategory(articleDetail);
    @include articleTagColor;

    & + .cmp-article-title__text {
      margin-top: 16px;
    }
  }


  &__text {
    font-size: 28px;
    line-height: 1.5;
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }
  &__text::after {
    content: "";
    display: block;
    width: 64px;
    height: 3px;
    border-radius: 1.5px;
    margin-top: 24px;
    background-color: #00AD92;
  }
}
