.ceh-card {
  $root: &;

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    row-gap: 48px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &-column2 &__content,
  &-column3 &__content {
    column-gap: 48px;
  }
  &-column4 &__content {
    column-gap: 20px;
  }
  @include media-breakpoint-down(md) {
    &-column2 &__content,
    &-column3 &__content,
    &-column4 &__content {
      row-gap: 0;
      column-gap: 0;
    }
  }

  &__grid {
    display: flex;
    width: 100%;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  &__item {
    display: flex;
    width: 100%;
    flex-direction: column;

    padding-bottom: 24px;
    text-decoration: none;
  }

  .ceh-card-revers,
  .ceh-card-horizon {
    row-gap: 0;

    @include media-breakpoint-down(md) {
      .ceh-card__grid {
        margin-bottom: 0;
      }
    }

    .ceh-card__item {
      border: solid #CFD8DC;
      border-width: 1px 0;

      padding-top: 24px;
    }
  }

  &__item.ceh-card-horizon {
    margin-top: 0;
  }

  &-column2 &__grid {
    width: calc(50% - 24px);
  }
  &-column3 &__grid {
    width: calc(33.33% - 32px);
  }
  &-column4 &__grid {
    width: calc(25% - 15px);
  }
  &-column2 &__grid,
  &-column3 &__grid,
  &-column4 &__grid {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  // ボーダー設定（「列」が同じカードの上ボーダーは非表示。水平ボーダーの重複回避）
  @mixin collapsedBorder($columnCount) {
    .ceh-card-revers, .ceh-card-horizon {
      .ceh-card__grid:nth-child(n+#{$columnCount + 1}) .ceh-card__item {
        border-top-width: 0;
      }
      @include media-breakpoint-down(md) {
        .ceh-card__grid:nth-child(n+2) .ceh-card__item {
          border-top-width: 0;
        }
      }
    }
  }

  &:not(&-column2, &-column3, &-column4) {
    @include collapsedBorder(1);
  }
  @for $columnCount from 2 through 4 {
    &-column#{$columnCount} {
      @include collapsedBorder($columnCount);
    }
  }

  &__image {
    border: 1px solid #CFD8DC;
    border-radius: 10px;
    overflow: hidden;
    will-change: transform;
    order: 1;
    img {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      vertical-align: bottom;
      transition: transform 0.3s;
      transform-origin: center center;
    }
  }
  &-revers &__image {
    margin-top: 16px;
    order: 2;
  }
  a.ceh-card__item:hover &__image img {
    transform: scale(1.1);
  }

  &__text {
    padding-top: 16px;
    order: 2;
  }
  &-revers &__text {
    padding-top: 0;
    // border-top: 1px solid #CFD8DC;
    order: 1;
  }
  &-no-image &__text {
    padding-top: 0;
    // border-top: 1px solid #CFD8DC;
  }

  &__heading {
    margin-bottom: 0;
    padding-left: 12px;
    padding-right: 12px;
    color: #3C3C40;
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  a.ceh-card__item:hover &__heading {
    text-decoration: underline;
    color: #2C31A6;
  }
  &__caption {
    margin-top: 16px;
    margin-bottom: 0;
    padding-left: 12px;
    padding-right: 12px;
    color: #3C3C40;
    font-size: 14px;
    line-height: 2.0;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }

    p {
      margin: 0;
      width: 100%;
    }
  }


  &-horizon &__item{
    flex-direction: row;
    align-items: flex-start;
  }

  &-horizon &__image {
    width: 33.3%;
    margin-right: 8px;
    order: 1;
  }
  &-horizon.ceh-card-horizon-revers &__image {
    margin-left: 8px;
    margin-right: 0;
    order: 2;
  }
  &-horizon &__text {
    width: 66.6%;
    margin-left: 8px;
    // border-top: 1px solid #CFD8DC;
    order: 2;
    padding-top: 0;
  }
  &-horizon.ceh-card-no-image &__text {
    width: 100%;
    margin-left: 0;
  }
  &-horizon.ceh-card-horizon-revers &__text {
    margin-left: 0;
    margin-right: 8px;
    order: 1;
  }
  &-horizon.ceh-card-horizon-revers.ceh-card-no-image &__text {
    margin-right: 0;
  }
  &-horizon &__heading,
  &-horizon &__caption {
    padding-left: 0;
    padding-right: 0;
  }
  
  a.ceh-card__item &__heading {
    text-indent: calc(-1.14em - 4px);
    padding-left:  calc(1.14em + 4px);

    &::before {
      content: "";
      width: 1.14em;
      height: 1.14em;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 4px;
      display: inline-block;
      vertical-align: -3px;
    }
  }

  a.ceh-card__item[target="_blank"] &__heading {
    &::after {
      content: "";
      width: 1.14em;
      height: 1.14em;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cg fill='%2300AD92' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M1.75 8.667c0-.322.261-.584.583-.584H3.99V9.25H2.917v3.5h5v-1.42h1.166v2.003a.583.583 0 0 1-.583.584H2.333a.583.583 0 0 1-.583-.584V8.667Z'/%3e%3cpath d='M5.417 2.333c0-.322.26-.583.583-.583h8c.322 0 .583.261.583.583v7a.583.583 0 0 1-.583.584H6a.583.583 0 0 1-.583-.584v-7Zm1.166.584V8.75h6.834V2.917H6.583Z'/%3e%3c/g%3e%3c/svg%3e");
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  a.ceh-card__item[href$=".pdf"] &__heading,
  // a.ceh-card__item[href$=".html"] &__heading,
  a.ceh-card__item[href$=".xls"] &__heading,
  a.ceh-card__item[href$=".xlsx"] &__heading,
  a.ceh-card__item[href$=".zip"] &__heading {
    text-indent: calc(-1em - 8px);
    padding-left:  calc(1em + 8px);

    &::before {
      content: "";
      width: 1em;
      height: 1em;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 4px;
      display: inline-block;
      vertical-align: -3px;
    }
  }
  a.ceh-card__item[href$=".pdf"] &__heading {
    &::before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3e%3cg fill='%23BA2B12' clip-path='url(%23a)'%3e%3cpath d='M8.385 2.042h.006l3.567 3.573-.008 6.343-9.908-.008.008-9.908h6.335Zm0-.875H2.05a.885.885 0 0 0-.883.883v9.9c0 .487.396.883.883.883h9.9a.885.885 0 0 0 .883-.883V5.615a.883.883 0 0 0-.26-.625L9.01 1.426a.883.883 0 0 0-.625-.26Z'/%3e%3cpath d='M12.25 6.27h-3.5a1.02 1.02 0 0 1-1.02-1.02v-3.5h.874v3.5c0 .082.064.146.146.146h3.5v.875ZM3.392 9.713v1.205h-.694V7.497h1.225c.787 0 1.28.361 1.28 1.096 0 .735-.487 1.12-1.28 1.12h-.53Zm.432-.586c.481 0 .685-.169.685-.537 0-.326-.204-.504-.685-.504h-.432v1.041h.432Zm2.146 1.791V7.497h1.214c.773 0 1.312.396 1.312 1.487v.446c0 1.085-.54 1.488-1.312 1.488H5.97ZM7.805 9.43v-.446c0-.589-.157-.898-.618-.898h-.522v2.246h.522c.46 0 .618-.312.618-.902ZM11.5 7.497v.589h-1.438v.849h1.29v.586h-1.29v1.397h-.694V7.497H11.5Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h11.667v11.667H0z' transform='translate(1.167 1.167)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    }
  }

  a.ceh-card__item[href$=".xls"] &__heading,
  a.ceh-card__item[href$=".xlsx"] &__heading,
  a.ceh-card__item[href$=".zip"] &__heading {
    &::before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'%3e%3cg fill='%2300AD92' clip-path='url(%23a)'%3e%3cpath d='M28.75 7h.02L41 19.25 40.97 41 7 40.97 7.03 7h21.72Zm0-3H7.03C5.36 4 4 5.36 4 7.03v33.94C4 42.64 5.36 44 7.03 44h33.94c1.67 0 3.03-1.36 3.03-3.03V19.25c0-.8-.32-1.57-.89-2.14L30.89 4.89c-.57-.57-1.34-.89-2.14-.89Z'/%3e%3cpath d='M42 21.5H30c-1.93 0-3.5-1.57-3.5-3.5V6h3v12c0 .28.22.5.5.5h12v3Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h40v40H0z' transform='translate(4 4)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    }
  }

  @include ceh-margin-bottom('&');
}