.cmp-text {
  .cmp-text--fontsize-normal {
    font-size: 16px;
    line-height: 1.8;
  }

  .cmp-text--fontsize-option {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .cmp-text--fontsize-annotation {
    font-size: 12px;
    line-height: lh(12, 18);
  }
}