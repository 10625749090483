.cmp-breadcrumb {
  width: 100%;

  @include media-breakpoint-down(md) {
    border-top: 1px solid #CFD8DC;
  }

  @include media-breakpoint-up(md) {
    background-color: #ffffff;
  }


  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    margin: 0;

    @include media-breakpoint-down(md) {
      padding: 16px;
    }

    @include media-breakpoint-up(md) {
      padding: 16px 32px;
    }
  }


  &__item {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: lh(12, 18);
    word-break: break-word;
    overflow-wrap: break-word;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      margin-left: 6px;
      margin-right: 6px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' viewBox='0 0 12 12'%3e%3cpath fill='%237F7F7F' fill-rule='evenodd' d='M3.521 1.771a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708l3.647-3.646L3.52 2.479a.5.5 0 0 1 0-.708Z' clip-rule='evenodd'/%3e%3c/svg%3e");
    }

    &--active {
      color: #3C3C40;
      &::after {
        display: none;
      }
    }

    &-link {
      color: $color-link;
      text-decoration: none;

      &:hover {
        color: $color-cosmo-blue;
        text-decoration: underline;
      }
    }
  }
}
