.cmp-title--style-h4 {
  margin-bottom: 16px;
  .cmp-title {
    .cmp-title__text {
      margin-bottom: 16px;
      padding-left: 19px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5;
      position: relative;
      &::before {
        content: "";
        width: 3px;
        height: calc(100% - 0.5em);
        background-color: #00ad92;
        border-radius: 1.5px;
        margin: auto 0;
        display: block;
        position: absolute;
        top: 0.125em;
        bottom: 0;
        left: 0;
      }

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
  }
}