.cmp-image--style-trimming {
  .cmp-image {
    &__image {
      @include media-breakpoint-down(md) {
        height: calc(100 / 375 * 200 * 1vw);
        object-fit: cover;
      }
    }
  }
}
