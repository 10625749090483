.cmp-container--main {
  > .cmp-container {
    @include media-breakpoint-down(md) {
      padding-left: $content-side-space-for-mobile;
      padding-right: $content-side-space-for-mobile;
    }

    @include media-breakpoint-up(md) {
      max-width: 1176px;
      padding-left: 60px;
      padding-right: 60px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
