
//== Font

$font-family: 'Noto Sans JP', sans-serif;
$font-family-en: 'Noto Sans', sans-serif;

$font-family-en-accent: 'Montserrat', sans-serif;

$font-family-title: 'Noto Sans JP', sans-serif;
$font-family-title-en: 'Noto Sans', sans-serif;

$font-size: 16px;
$font-height: 1.8;

//== Color

$color-background: #fff;
$color-foreground: #3c3c40;
$color-link: #006050;
$color-accent: #f5f7fd;

$color-text: $color-foreground;
$color-text-inverted: $color-background;

$color-cosmo-red: #fc2e14;
$color-cosmo-green: #00ad92;
$color-cosmo-blue: #2c31a6;


//== Breakpoint

$breakpoints-down: (
  'md': 'print,screen and (max-width: 899px)',
  'md--header': 'print,screen and (max-width: 1254px)'
) !default;

$breakpoints-up: (
  'md': 'print,screen and (min-width: 900px)',
  'md--header': 'print,screen and (max-width: 1255px)'
) !default;

//== Side Space for Main-Contents on Mobile
$content-side-space-for-mobile: 20px;

//== Width
$content-width: 1176;
