.cmp-teaser--style-card-horizontal-right {
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding-left: 60px;
    padding-right: 60px;
  }


  .cmp-teaser {
    box-sizing: border-box;
    display: flex;
    position: relative;
    background-color: $color-accent;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      margin-top: 28px;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      max-width: 1176px;
      min-height: 540px;
      padding-top: 110px;
      padding-bottom: 110px;
      border-radius: 15px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 80px;
    }


    &__image {
      @include media-breakpoint-down(md) {
        margin-top: -28px;
        margin-left: 30px;
        margin-right: 0;
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        top: -80px;
        right: calc(132 / 1176 * 100% * -1);
        width: calc(720 / 1176 * 100%);
      }

      .cmp-image__image {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;

        @include media-breakpoint-up(md) {
          height: 540px;
          object-fit: cover;
        }
      }
    }


    &__content {
      box-sizing: border-box;

      @include media-breakpoint-down(md) {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 28px;
        margin-top: 16px;
      }

      @include media-breakpoint-up(md) {
        width: calc(590 / 1176 * 100%);
        padding-left: calc(80 / 1176 * 100%);
      }
    }


    &__pretitle {
      margin: 0;
      color: $color-link;
      font-family: $font-family-en-accent;
      font-size: 12px;
      font-weight: 700;
      line-height: lh(12, 15);
      letter-spacing: .15em;
      word-break: break-word;
      overflow-wrap: break-word;

      & + .cmp-teaser__title {
        @include media-breakpoint-down(md) {
          margin-top: 16px;
        }

        @include media-breakpoint-up(md) {
          margin-top: 8px;
        }
      }
    }


    &__title {
      color: #3c3c40;
      font-weight: 700;
      letter-spacing: .1em;
      word-break: break-word;
      overflow-wrap: break-word;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: lh(24, 35);
      }

      @include media-breakpoint-up(md) {
        font-size: 34px;
        line-height: lh(34, 49);
      }
    }


    &__description {
      position: relative;
      border-top-style: solid;
      border-top-color: $color-cosmo-blue;
      border-top-width: 2px;
      color: $color-text;
      font-size: 16px;
      line-height: lh(16, 32);
      word-break: break-word;
      overflow-wrap: break-word;

      @include media-breakpoint-down(md) {
        padding-top: 32px;
        margin-top: 16px;
      }

      @include media-breakpoint-up(md) {
        padding-top: 24px;
        margin-top: 16px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        border-top-style: solid;
        border-top-width: 2px;
      }

      &::before {
        left: 0;
        top: -2px;
        width: calc(500 / 1440 * 100%);
        border-top-color: $color-cosmo-green;
      }

      &::after {
        top: -2px;
        right: 0;
        width: calc(87 / 1440 * 100%);
        border-top-color: $color-cosmo-red;
      }

      > * {
        @include media-breakpoint-up(md) {
          width: calc(410 / 510 * 100%);
        }
      }

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }


    &__action-container {
      margin-top: 32px;
    }


    &__action-link {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      position: relative;
      padding-top: 17px;
      padding-bottom: 16px;
      border-style: solid;
      border-width: 1px;
      border-color: $color-cosmo-blue;
      border-radius: 100vh;
      background-color: $color-accent;
      color: #3c3c40;
      font-size: 14px;
      font-weight: 700;
      line-height: lh(14, 20);
      text-decoration: none;
      word-break: break-word;
      overflow-wrap: break-word;

      @include media-breakpoint-down(md) {
        width: calc(280 / 315 * 100%);
      }

      @include media-breakpoint-up(md) {
        min-width: 280px;
      }

      &::before {
        content: '';
        width: 6px;
        height: 10px;
        margin-left: 25px;
        margin-right: 10px;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%232C31A6%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%232C31A6%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $color-cosmo-blue;
        color: #fff;

        &::before {
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.608%22%20height%3D%228.002%22%20viewBox%3D%220%200%204.608%208.002%22%3E%3Cg%20transform%3D%22translate(-31.905%2013.923)%22%20fill%3D%22%23FFFFFF%22%3E%3Cpath%20d%3D%22M32.094-6.1a.6.6%2C0%2C0%2C1%2C0-.849l2.97-2.97L32.081-12.9a.6.6%2C0%2C0%2C1%2C0-.849.6.6%2C0%2C0%2C1%2C.849%2C0l3.394%2C3.394.021.022a.6.6%2C0%2C0%2C1-.007.84L32.943-6.1a.6.6%2C0%2C0%2C1-.424.175A.6.6%2C0%2C0%2C1%2C32.094-6.1Z%22%20stroke%3D%22none%22%2F%3E%3Cpath%20d%3D%22M%2032.5186882019043%20-5.921100616455078%20C%2032.36512756347656%20-5.921100616455078%2032.21144866943359%20-5.979600429534912%2032.09399795532227%20-6.096600532531738%20C%2031.85999870300293%20-6.33150053024292%2031.85999870300293%20-6.711300849914551%2032.09399795532227%20-6.945300579071045%20L%2035.06439971923828%20-9.914910316467285%20L%2032.08049774169922%20-12.89880084991455%20C%2031.84649848937988%20-13.1328010559082%2031.84649848937988%20-13.51260089874268%2032.08049774169922%20-13.7475004196167%20C%2032.31539535522461%20-13.98149871826172%2032.69519424438477%20-13.98150444030762%2032.92919921875%20-13.7475004196167%20L%2036.32309722900391%20-10.35360050201416%20C%2036.33023071289062%20-10.34647083282471%2036.33710861206055%20-10.33923053741455%2036.34379959106445%20-10.33184051513672%20C%2036.57143783569336%20-10.09731101989746%2036.56906890869141%20-9.722970962524414%2036.33659744262695%20-9.491400718688965%20L%2032.94269943237305%20-6.096600532531738%20C%2032.82569885253906%20-5.979600429534912%2032.67224884033203%20-5.921100616455078%2032.5186882019043%20-5.921100616455078%20Z%22%20stroke%3D%22none%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        }
      }
    }
  }
}
