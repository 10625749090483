.cmp-button--icon-default {
  &.cmp-button--style-default {
    .cmp-button {
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        margin-left: 16px;
        margin-right: 16px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' fill='none' viewBox='0 0 14 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.108 4.067a.583.583 0 0 1 .825 0L9.6 8.733a.583.583 0 0 1 0 .825l-4.667 4.667a.583.583 0 1 1-.825-.825l4.255-4.254-4.255-4.254a.583.583 0 0 1 0-.825Z' clip-rule='evenodd'/%3e%3c/svg%3e");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }


  &.cmp-button--style-anchor {
    .cmp-button {
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        margin-left: 16px;
        margin-right: 4px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='%2300AD92' d='M7.72 9.527a.7.7 0 0 1 .988 0l3.461 3.462 3.463-3.462a.701.701 0 1 1 .99.995l-3.957 3.951a.7.7 0 0 1-.99 0l-.02-.022-3.936-3.936a.7.7 0 0 1 0-.988Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' d='M0 0h24v24H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
