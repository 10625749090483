.cmp-teaser--style-hero-image {
  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      overflow: hidden;
      min-height: 328px;
    }

    @include media-breakpoint-up(md) {
      height: 360px;
    }

    &__image {
      position: absolute;
      z-index: 1;
      width: 100%;

      .cmp-image__image {
        object-fit: cover;

        @include media-breakpoint-down(md) {
          min-height: 328px;
        }

        @include media-breakpoint-up(md) {
          height: 360px;
        }
      }
    }


    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;

      @include media-breakpoint-down(md) {
        padding-left: 32px;
        padding-right: 32px;
      }

      @include media-breakpoint-up(md) {
        padding-left: 60px;
        padding-right: 60px;
      }
    }


    &__title {
      position: relative;
      color: #FFFFFF;
      font-size: 48px;
      font-weight: 700;
      line-height: 1.5;
      padding-bottom: calc(24px + 3px);

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        content: '';
        width: 64px;
        height: 3px;
        border-radius: 1.5px;
        background-color: #00AD92;
      }

      @include media-breakpoint-down(md) {
        padding-bottom: calc(16px + 3px);
        font-size: 28px;
      }
    }


    &__pretitle {
      display: none;
      order: 2;
      margin-top: 16px;
      margin-bottom: 0;
      color: $color-text-inverted;
      font-family: $font-family-en-accent;
      font-weight: 700;
      letter-spacing: .15em;
      text-align: center;
      word-break: break-word;
      overflow-wrap: break-word;

      @include media-breakpoint-down(md) {
        font-size: 31px;
        line-height: lh(31, 38);
      }

      @include media-breakpoint-up(md) {
        font-size: 74px;
        line-height: lh(74, 91);
      }
    }


    &__description {
      margin: 48px auto 0;
      color: #FFFFFF;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.8;

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }
}
